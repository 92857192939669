/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Carousel, Container, Row, Col, Button } from "react-bootstrap";
import feedback1 from "../../public/images/feedback/feed1.png";
import { useNavigate } from "react-router-dom";
import banner1 from "../../public/images/banner/banner1.png";
import banner2 from "../../public/images/banner/banner2.png";
import banner3 from "../../public/images/banner/banner3.png";
import Usage from "./Usage";
import { products } from "../../component/data";

interface FeedbackType {
  name: string;
  location: string;
  img: string;
  feedback: string;
}
interface BannerType {
  src: string;
  alt: string;
}

const feedbackList: FeedbackType[] = [
  {
    name: "Anh Hoàng",
    location: "TP.HCM",
    img: feedback1,
    feedback:
      "Là món quà ý nghĩa cho người thân và gia đình. Mình luôn tin tưởng sản phẩm Đông Trùng Hạ Thảo KOVI.",
  },
  {
    name: "Chị Trang",
    location: "Sơn La",
    img: feedback1,
    feedback:
      "Nhân viên tư vấn rất nhiệt tình, sản phẩm chất lượng uy tín. Mình sẽ giới thiệu cho bạn bè và người thân.",
  },
  {
    name: "Chị An",
    location: "Hà Nội",
    img: feedback1,
    feedback:
      "Công việc của tôi khá bận rộn, nhưng tôi vẫn hài lòng với sản phẩm đông trùng hạ thảo KOVI vì chất lượng.",
  },
  {
    name: "Anh Bình",
    location: "Đà Nẵng",
    img: feedback1,
    feedback:
      "Sản phẩm này giúp mình cải thiện sức khỏe đáng kể. Dịch vụ chăm sóc khách hàng cũng rất tốt.",
  },
  {
    name: "Chị Lan",
    location: "Hải Phòng",
    img: feedback1,
    feedback:
      "Hàng chính hãng, chất lượng tuyệt vời. Mình sẽ tiếp tục ủng hộ trong thời gian tới.",
  },
];

const bannerList: BannerType[] = [
  { src: banner1, alt: "Image 1" },
  { src: banner2, alt: "Image 2" },
  { src: banner3, alt: "Image 3" },
];

const chunkArray = <T,>(array: T[], size: number): T[][] => {
  return Array.from({ length: Math.ceil(array.length / size) }, (_, i) =>
    array.slice(i * size, i * size + size)
  );
};

const Home: React.FC = () => {
  const groupedTestimonials: FeedbackType[][] = chunkArray(feedbackList, 3);
  const navigate = useNavigate();
  const randomProducts = [...products].sort(() => 0.5 - Math.random()).slice(0, 6);

  return (
    <>
      <Carousel
        indicators={false}
        fade
        interval={3000}
        className="custom-carousel"
      >
        {bannerList.map((image, index) => (
          <Carousel.Item key={index}>
            <div className="d-flex justify-content-center">
              <img
                src={image.src}
                alt={image.alt}
                className="responsive-image"
              />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>

      <Container className="py-5">
        <Row className="align-items-center">
          <Col lg={6}>
            <h2 className="fw-bold">Đông trùng hạ thảo KOVI</h2>
            <p>
              Đông trùng hạ thảo KOVI là đơn vị tiên phong áp dụng sản xuất nông
              nghiệp công nghệ cao và giá trị trong sản xuất các sản phẩm nông
              nghiệp tại Việt Nam. Sản phẩm Đông Trùng Hạ Thảo được nuôi trồng
              thành công là kết quả nghiên cứu, chuyển giao công nghệ giữa Việt
              Nam và các nước Hàn Quốc, Nhật Bản, Trung Quốc và Các đơn vị
              nghiên cứu hàng đầu trong Nước.
            </p>
            <p>
              Ngoài ra, đông trùng hạ thảo thường được dùng làm thuốc bổ dùng
              cho người gầy yếu, người mới khỏi bệnh hiểm nghèo. Những tác dụng
              khác của đông trùng hạ thảo vẫn đang được nghiên cứu và phát hiện
              thêm từng ngày.
            </p>

            <Button variant="warning" className="text-white fw-bold" onClick={() => navigate("/about")}>
              Xem thêm
            </Button>
          </Col>

          <Col md={6}>
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/seJrsViosPw?si=-auqRmDzSonRHP3X"
                title="VTV2 phóng sự về Đông Trùng Hạ Thảo KOVI"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="no-referrer"
                allowFullScreen
              />
            </div>
          </Col>
        </Row>
      </Container>

      <div className="site-section">
        <div className="container">
          <div className="row">
            <div className="title-section text-center col-12">
              <h2 className="text-uppercase">Sản phẩm Kovi</h2>
            </div>
          </div>

          <div className="row">
            {randomProducts.map((product, index) => (
              <div
                className="col-sm-6 col-lg-4 text-center mb-4 p-3"
                key={index}
              >
                <div className="card h-100 border-0 shadow-sm p-3">
                  <a
                    href="shop-single.html"
                    className="d-block overflow-hidden rounded-3 position-relative"
                  >
                    <img
                      src={product.img}
                      alt="Image"
                      className="w-100 rounded-3 img-fluid object-fit-cover zoom-effect transition-transform hover-scale"
                      style={{
                        height: "250px",
                        transition: "transform 0.3s ease-in-out",
                      }}
                    />
                  </a>
                  <div className="card-body d-flex flex-column">
                    <h3 className="text-dark flex-grow-1">
                      <a
                        href="shop-single.html"
                        className="text-decoration-none"
                      >
                        {product.name}
                      </a>
                    </h3>
                    <p className="price mb-0">
                      <span className="fw-bold">{product.price}</span>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="row mt-5">
            <div className="col-12 text-center">
              <Button
                variant="warning"
                className="text-white fw-bold"
                onClick={() => navigate("/product")}
              >
                Xem thêm sản phẩm khác
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Usage />

      <div className="container my-5">
        <h2 className="text-center mb-4 fw-bold">CẢM NHẬN CỦA KHÁCH HÀNG</h2>
        <Carousel
          indicators={false}
          fade
          interval={3000}
          className="custom-carousel"
        >
          {groupedTestimonials.map((group, index) => (
            <Carousel.Item key={index}>
              <div className="row justify-content-center">
                {group.map((testimonial, idx) => (
                  <div
                    className="col-md-4 d-flex justify-content-center"
                    key={idx}
                  >
                    <div
                      className="card text-center p-4 shadow-sm border-0 rounded-3"
                      style={{ width: "90%" }}
                    >
                      <img
                        src={testimonial.img}
                        alt={testimonial.name}
                        className="rounded-circle mx-auto d-block mb-3"
                        width="80"
                        height="80"
                      />
                      <h5 className="fw-bold">{testimonial.name}</h5>
                      <p className="text-muted">{testimonial.location}</p>
                      <p className="mb-0">{testimonial.feedback}</p>
                    </div>
                  </div>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default Home;
