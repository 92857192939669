import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import condung from "../../public/images/banner/congdung.png";
const benefitsLeft = [
  {
    title: "TÁC DỤNG THANH LỌC",
    desc: "Hỗ trợ thanh lọc chất độc hạn chế các tác động xấu đến cơ thể",
  },
  {
    title: "TĂNG CƯỜNG SINH LÝ",
    desc: "Kích thích cường dương chống liệt dương cho phái mạnh cực tốt",
  },
  {
    title: "HỖ TRỢ TIÊU HOÁ",
    desc: "Giúp hệ tiêu hóa khỏe mạnh tăng khả năng hấp thụ đầy đủ chất dinh dưỡng",
  },
  {
    title: "CHỐNG LÃO HOÁ",
    desc: "Giúp phụ nữ da mịn màng xóa bỏ các hiện tượng da nám màu.",
  },
];

const benefitsRight = [
  {
    title: "TĂNG SỨC ĐỀ KHÁNG",
    desc: "Giúp ngăn ngừa sự xâm nhập vi trùng, virus vào cơ thể",
  },
  {
    title: "TĂNG CƯỜNG SỨC KHỎE",
    desc: "Đẩy mạnh chuyển hóa, tăng lưu thông máu chống mệt mỏi",
  },
  {
    title: "GIẢM LƯỢNG CHOLESTEROL",
    desc: "Giảm cholesterol trong máu, hạn chế xơ vữa động mạch",
  },
  {
    title: "PHÒNG BỆNH GAN THẬN",
    desc: "Có thể làm giảm tổn thương thận gan do các loại thuốc gây ra",
  },
];

const Usage: React.FC = () => {
  return (
    <Container
      className="text-center py-5"
      style={{
        background: "linear-gradient(to bottom, #fff4d6, #f6d365)",
        minHeight: "80%",
      }}
    >
      <h2 className="fw-bold text-uppercase">
        Công dụng tuyệt vời của{" "}
        <span className="text-danger">Đông Trùng Kovi</span>
      </h2>
      <p className="text-muted">Trao sức khoẻ - Tạo niềm vui</p>

      <Row className="align-items-center my-5">
        <Col md={4}>
          {benefitsLeft.map((item, index) => (
            <div key={index} className="text-start mb-4">
              <h5 className="fw-bold">{item.title}</h5>
              <p>{item.desc}</p>
            </div>
          ))}
        </Col>

        <Col md={4} className="text-center">
          <img
            src={condung} 
            alt="Đông Trùng Hạ Thảo"
            className="img-fluid"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Col>

        <Col md={4}>
          {benefitsRight.map((item, index) => (
            <div key={index} className="text-start mb-4">
              <h5 className="fw-bold">{item.title}</h5>
              <p>{item.desc}</p>
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default Usage;
