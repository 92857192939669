import { createContext, useContext, useState, ReactNode } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

interface ToastContextType {
  showToast: (message: string, type?: "success" | "danger" | "warning") => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);
let globalShowToast:
  | ((message: string, type?: "success" | "danger" | "warning") => void)
  | null = null;

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState<"success" | "danger" | "warning">(
    "success"
  );

  const showToast = (
    msg: string,
    type: "success" | "danger" | "warning" = "success"
  ) => {
    setMessage(msg);
    setVariant(type);
    setShow(true);
  };

  globalShowToast = showToast;

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
          bg={variant}
        >
          <Toast.Header>
            <strong className="me-auto">Thông báo</strong>
          </Toast.Header>
          <Toast.Body>{message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

export const showGlobalToast = (
  message: string,
  type?: "success" | "danger" | "warning"
) => {
  if (globalShowToast) {
    globalShowToast(message, type);
  }
};
