import { Container, Row, Col, Image } from "react-bootstrap";
import product01 from "../../public/images/product/product1.png";
import MenuItemnews from "../../component/menuItem/menuItemNew";
import { useState } from "react";
import { NewsType } from "../../types";
import { news } from "../../component/data";

const News = () => {
  const [menuItems, setMenuItems] = useState<NewsType>();

  return (
    <Container className="my-5">
      <Row>
        <Col md={9} style={{ overflow: "hidden" }}>
          <h2 className="fw-bold" onClick={() => setMenuItems(undefined)}>
            TIN TỨC
          </h2>
          {menuItems ? (
            <>
              <p className="fw-bold">{menuItems.title}</p>
              <div
              className="content-container"
                style={{ wordWrap: "break-word", overflow: "hidden" }}
                dangerouslySetInnerHTML={{ __html: menuItems.content }}
              />
            </>
          ) : (
            news.map((news) => (
              <Row
                key={news.id}
                className="my-3 align-items-center"
                onClick={() => setMenuItems(news)}
              >
                <Col xs={4}>
                  <Image
                    src={news.img}
                    alt={news.title}
                    thumbnail
                    className="img-fluid"
                    style={{ width: "auto", height: "auto" }}
                  />
                </Col>
                <Col xs={8} style={{ overflow: "hidden" }}>
                  <h6 className="fw-bold text-truncate">{news.title}</h6>
                  <p className="text-muted text-truncate">{news.description}</p>
                </Col>
              </Row>
            ))
          )}
        </Col>

        <Col md={3} className="border-start border-1 border-muted">
          <MenuItemnews setMenuItems={(value) => setMenuItems(value)} />
        </Col>
      </Row>
    </Container>
  );
};

export default News;
