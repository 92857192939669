import { Card, ListGroup } from "react-bootstrap";


import { knowledgeItems, news, sibarItem } from "../data";
import { NewsType } from "../../types";


interface MenuItemnewsProps {
  setMenuItems: (value: NewsType) => void;
}

const MenuItemnews: React.FC<MenuItemnewsProps> = ({ setMenuItems }) => {
  return (
    <>
    <h5 className="fw-bold">THÔNG TIN</h5>
      <ListGroup className="mb-4">
        {sibarItem.map((item, index) => (
          <ListGroup.Item key={index} className="border-0 p-2" onClick={() => setMenuItems(item)}>
            <Card className="d-flex flex-row align-items-center border-0">
              <Card.Img
                variant="top"
                src={item.img}
                className="rounded"
                style={{ width: "100px", height: "auto" }}
              />
              <Card.Body className="p-0 ms-3">
                <Card.Text className="fw-bold text-dark small mb-0">
                  {item.title}
                </Card.Text>
              </Card.Body>
            </Card>
          </ListGroup.Item>
        ))}
      </ListGroup>

      <h5 className="fw-bold">KIẾN THỨC KHOA HỌC</h5>
      <ListGroup>
        {knowledgeItems.map((item, index) => (
          <ListGroup.Item key={index} className="border-0 p-2" onClick={() => setMenuItems(item)}>
            <Card className="d-flex flex-row align-items-center border-0">
              <Card.Img
                variant="top"
                src={item.img}
                className="rounded"
                style={{ width: "100px", height: "auto" }}
              />
              <Card.Body className="p-0 ms-3">
                <Card.Text className="fw-bold text-dark small mb-0">
                  {item.title}
                </Card.Text>
              </Card.Body>
            </Card>
          </ListGroup.Item>
        ))}
      </ListGroup>
      
      <h5 className="fw-bold">TIN TỨC</h5>
      <ListGroup>
        {news.map((item, index) => (
          <ListGroup.Item key={index} className="border-0 p-2" onClick={() => setMenuItems(item)}>
            <Card className="d-flex flex-row align-items-center border-0">
              <Card.Img
                variant="top"
                src={item.img}
                className="rounded"
                style={{ width: "100px", height: "auto" }}
              />
              <Card.Body className="p-0 ms-3">
                <Card.Text className="fw-bold text-dark small mb-0">
                  {item.title}
                </Card.Text>
              </Card.Body>
            </Card>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
};

export default MenuItemnews;
