import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import FromContact from "../contact";

const Footer: React.FC = () => {
  return (
    <footer className="py-5">
      <Container>
        <Row>
          {/* Cột thông tin liên hệ */}
          <Col md={6} className="mb-4">
            <h3 className="mb-3">Liên hệ</h3>
            <p>
              <strong>
                Hợp tác xác nông nghiệp dược liệu công nghệ cao KOVI
              </strong>
            </p>
            <p>Địa chỉ: Mai Lâm - Đông Anh - Hà Nội - Việt Nam</p>
            <p>Email: namquachvan911@gmail.com</p>
            <p>
              <strong>Điện thoại: 0929 - 628 - 097</strong>
            </p>
            <h5 className="mt-4">Chính sách</h5>
            <p>Hướng dẫn mua hàng | Chính sách bảo hành | Chính sách bảo mật</p>
          </Col>

          <Col md={6}>
            <h4 className="mb-3">Đăng ký tư vấn</h4>
            <FromContact />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
