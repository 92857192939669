import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { showGlobalToast } from "../component/hook/useToast";

type AnyElement = any;

const STATUS_TOKEN_EXPIRED = 401;
const API_REQUEST_TIMEOUT = 60000;

let isRefreshing = false;
let failedQueue: AnyElement[] = [];

const processQueue = (error: AnyElement, token: AnyElement = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: API_REQUEST_TIMEOUT,
  responseType: "json",
  withCredentials: false,
});

const handleRequest = (req: AxiosRequestConfig) => {
  req.headers = req.headers ?? {};
  const token = localStorage.getItem("accessToken");
  if (token) {
    if (!req.headers["Authorization"]) {
      req.headers["Authorization"] = `Bearer ${token}`;
    }
  }
  req.headers["Accept-Language"] = "vi-VN";
  req.headers["Accept"] = "*";
  return req;
};

const handleRequestError = (error: AnyElement) => {
  return Promise.reject(error);
};

const resInterceptor = (response: AxiosResponse) => {
  return response;
};

const errInterceptor = (error: AnyElement) => {
  const httpCode = error?.response?.status;
  const config = error?.response?.config;

  if (httpCode === STATUS_TOKEN_EXPIRED && config?.url !== 'auth/refresh') {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          error.config.headers["Authorization"] = "Bearer " + token;
          return axiosClient(error.config);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }

    error.config._retry = true;
    isRefreshing = true;

    return axios
      .post<AnyElement>('auth/refresh', {}, { 
        baseURL: process.env.REACT_APP_API_URL, 
        withCredentials: true 
      })
      .then((response) => {
        const token = response?.data?.accessToken;
        localStorage.setItem("accessToken", token);
        processQueue(null, token);
        return axiosClient.request({
          ...config,
          headers: { ...config?.headers, Authorization: `Bearer ${token}` },
        });
      })
      .catch((errorRefresh) => {
        processQueue(errorRefresh, null);
        if (errorRefresh.status === 401) {
          showGlobalToast("Phiên đăng nhập hết hạn, vui lòng đăng nhập lại", "warning");
        }
        return Promise.reject(errorRefresh?.response?.data);
      })
      .finally(() => {
        isRefreshing = false;
      });
  }

  const CError: AnyElement = error?.response?.data;
  if (CError?.message) {
    showGlobalToast(CError?.message, "danger");
  }
  return Promise.reject(CError);
};

axiosClient.interceptors.request.use(handleRequest as AnyElement, handleRequestError);
axiosClient.interceptors.response.use(resInterceptor, errInterceptor);

export { axiosClient };
