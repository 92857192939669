import React, { useRef, useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { axiosClient } from "../../config/axiosClient";

const FromContact: React.FC = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<{
    message: string;
    type: "success" | "danger" | "error" | "";
  }>({
    message: "",
    type: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const response = await axiosClient.post("/sale", data);
      console.log("User created:", response.data);
      setAlert({
        message: "Thông tin đã được ghi nhận! ✅",
        type: "success",
      });
      reset();
    } catch (error) {
      setAlert({ message: "Hệ thống xảy ra lỗi!", type: "error" });
    } finally {
      setIsLoading(false);
      setTimeout(() => setAlert({ message: "", type: "" }), 3000);
    }
  };

  return (
    <div className="p-4 border rounded" style={{ backgroundColor: "#f8f9fa" }}>
      {alert.message && (
        <div
          className={`alert alert-${alert.type} alert-dismissible fade show`}
          role="alert"
        >
          {alert.message}
          <button
            type="button"
            className="btn-close"
            onClick={() => setAlert({ message: "", type: "" })}
          ></button>
        </div>
      )}
      <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Họ và Tên</Form.Label>
          <Form.Control
            type="text"
            {...register("name", { required: "Vui lòng nhập họ và tên" })}
            placeholder="Nhập họ và tên"
          />
          {errors.name?.message && (
            <div className="text-danger">{String(errors.name.message)}</div>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            {...register("email", {
              required: "Vui lòng nhập email",
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: "Email không hợp lệ",
              },
            })}
            placeholder="Nhập email"
          />
          {errors.email?.message && (
            <div className="text-danger">{String(errors.email.message)}</div>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="phone">
          <Form.Label>Số điện thoại</Form.Label>
          <Form.Control
            type="tel"
            {...register("phone", {
              required: "Vui lòng nhập số điện thoại",
              pattern: {
                value: /^[0-9]{10}$/,
                message: "Số điện thoại phải có đúng 10 số",
              },
            })}
            placeholder="Nhập số điện thoại"
          />
          {errors.phone?.message && (
            <div className="text-danger">{String(errors.phone.message)}</div>
          )}
        </Form.Group>

        <Form.Group className="mb-3" controlId="description">
          <Form.Label>Mô tả</Form.Label>
          <Form.Control
            as="textarea"
            {...register("description")}
            rows={4}
            placeholder="Nhập thông tin cần tư vấn"
          />
        </Form.Group>

        <Button
          variant="warning"
          type="submit"
          className="w-100"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <Spinner animation="border" size="sm" /> Đang gửi...
            </>
          ) : (
            "Gửi thông tin"
          )}
        </Button>
      </Form>
    </div>
  );
};

export default FromContact;
