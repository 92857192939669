import { Container, Row, Col, Card } from "react-bootstrap";
import MenuItemnews from "../../component/menuItem/menuItemNew";
import procedure from "../../public/images/about/procedure.png";
import procedure1 from "../../public/images/about/procedure1.png";
import procedure2 from "../../public/images/about/procedure2.png";
import procedure3 from "../../public/images/about/procedure3.png";
import vision from "../../public/images/about/vision.png";
import mission from "../../public/images/about/mission.png";
import { useState } from "react";
import { NewsType } from "../../types";

const processSteps = [
  {
    image: procedure,
    text: "Nhà Xưởng đạt tiêu chuẩn Phòng Sạch HACCP tự động hóa hiện đại.",
  },
  {
    image: procedure1,
    text: "Nguồn nước cấp ẩm cho phòng nuôi đều được qua hệ thống xử lý đảm bảo.",
  },
  {
    image: procedure2,
    text: "Hệ thống lọc cung cấp khí tươi oxy vào từng phòng.",
  },
  {
    image: procedure3,
    text: "Cây giống được tuyển chọn lấy từ chủng Cordyceps Militaris ngoài tự nhiên.",
  },
];

const About = () => {
  const [menuItems, setMenuItems] = useState<NewsType>();
  return (
    <Container className="my-5">
      <Row>
        <Col md={9} style={{ overflow: "hidden" }}>
          <h2 className="fw-bold" onClick={() => setMenuItems(undefined)}>GIỚI THIỆU</h2>
          {menuItems ? (
            <>
              {" "}
              <p className="fw-bold">{menuItems.title}</p>
              <div
                className="content-container"
                style={{ wordWrap: "break-word", overflow: "hidden" }}
                dangerouslySetInnerHTML={{ __html: menuItems.content }}
              />{" "}
            </>
          ) : (
            <>
              <p className="fw-bold">
                Đông trùng hạ thảo KOVI là đơn vị tiên phong áp dụng sản xuất
                nông nghiệp công nghệ cao và giá trị trong sản xuất các sản phẩm
                nông nghiệp tại Việt Nam.
              </p>
              <p>
                Sản phẩm Đông Trùng Hạ Thảo được nuôi trồng thành công là kết
                quả nghiên cứu, chuyển giao công nghệ giữa Việt Nam và các nước
                Hàn Quốc, Nhật Bản, Trung Quốc và các đơn vị nghiên cứu hàng đầu
                trong nước.
              </p>
              <p>
                Để nghiên cứu tạo ra thành công chủng giống với hàm lượng hoạt
                chất tốt nhất. Đông trùng hạ thảo KOVI đã phải trải qua nhiều
                năm nghiên cứu chọn lọc những cá thể tốt nhất, rồi từ đó nhân
                giống, nuôi cấy thử nghiệm trước khi đưa vào sản xuất nuôi trồng
                quy mô công nghiệp.
              </p>
              <p>
                Vì vậy sản phẩm khi ra thị trường là thành phẩm tốt nhất đến tay
                người tiêu dùng, với giá thành hợp lý nhất ai cũng có thể tiếp
                cận.{" "}
              </p>
              <p>
                Chúng tôi tự hào là đơn vị sản xuất uy tín đưa ra các sản phẩm
                từ Đông Trùng Hạ Thảo tốt nhất.
              </p>
              <p>
                Đông trùng hạ thảo KOVI chung tay chăm sóc và bảo vệ sức khỏe
                cộng đồng, vì sức khỏe người Việt.
              </p>
              <p>
                Đông trùng hạ thảo KOVI cảm ơn quý khách hàng đã đặt niềm tin
                vào sản phẩm của chúng tôi.
              </p>
              <p>
                Chúc quý khách lời chúc: Sức Khỏe – An Khang – Thịnh Vượng.{" "}
              </p>
              <p>Xin chân thành cảm ơn!</p>
              <p>Đông trùng hạ thảo KOVI - Cho cuộc sống khỏe vui.</p>

              <Container>
                <h2 className="text-center fw-bold">QUY TRÌNH SẢN XUẤT</h2>
                <h3 className="text-center fw-bold text-uppercase mb-4">
                  ĐÔNG TRÙNG HẠ THẢO KOVI
                </h3>
                <p className="text-center text-muted">
                  TRANG THIẾT BỊ NHÀ XƯỞNG & CÔNG NGHỆ TIÊN TIẾN
                </p>
                <Row className="g-4 mt-4">
                  {processSteps.map((step, index) => (
                    <Col md={3} key={index}>
                      <Card className="border-0 text-center">
                        <Card.Img
                          variant="top"
                          src={step.image}
                          style={{
                            borderRadius: "10px",
                            height: "150px",
                            objectFit: "cover",
                          }}
                        />
                        <Card.Body>
                          <Card.Text className="fw-medium">
                            {step.text}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Container>
              <h2 className="text-center fw-bold">TẦM NHÌN</h2>
              <Container className="mt-4">
                <Row className="g-4">
                  <Col md={6}>
                    <Card className="shadow border-0 p-3 d-flex flex-column h-100">
                      <Row className="align-items-center flex-grow-1">
                        <Col xs={4} className="text-center">
                          <Card.Img
                            variant="top"
                            src={vision}
                            style={{
                              borderRadius: "2px",
                              height: "200px",
                              objectFit: "cover",
                            }}
                          />
                        </Col>
                        <Col xs={8} className="d-flex flex-column h-100">
                          <h5 className="fw-bold text-primary">TẦM NHÌN</h5>
                          <p className="mb-0 flex-grow-1 d-flex align-items-center">
                            Trở thành đơn vị nghiên cứu nuôi cấy và phát triển
                            các chế phẩm chăm sóc sức khỏe từ đông trùng hạ thảo
                            chất lượng cao hàng đầu cả nước. Xây dựng thành công
                            thương hiệu Đông trùng hạ thảo Vietfarm uy tín, là
                            sự lựa chọn đáng tin cậy của người tiêu dùng. Khát
                            vọng đưa "thiên dược" của Việt Nam vươn tầm thế
                            giới.
                          </p>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <Card className="shadow border-0 p-3 d-flex flex-column h-100">
                      <Row className="align-items-center flex-grow-1">
                        <Col xs={4} className="text-center">
                          <Card.Img
                            variant="top"
                            src={mission}
                            style={{
                              borderRadius: "2px",
                              height: "200px",
                              objectFit: "cover",
                            }}
                          />
                        </Col>
                        <Col xs={8} className="d-flex flex-column h-100">
                          <h5 className="fw-bold text-primary">SỨ MỆNH</h5>
                          <p className="mb-0 flex-grow-1 d-flex align-items-center">
                            Giữ gìn và bảo tồn dược liệu quý hiếm giúp nâng cao
                            sức khỏe con người, theo phương châm "Nam dược trị
                            Nam nhân". Không ngừng nghiên cứu, đặt hết tâm huyết
                            vào từng sản phẩm giúp nâng cao sức khỏe người Việt
                            đảm bảo đúng tiêu chí
                          </p>
                          <p>
                            <strong> AN TOÀN - HIỆU QUẢ - CHẤT LƯỢNG.</strong>
                          </p>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </>
          )}
        </Col>

        <Col md={3} className="border-start border-1 border-muted">
          <MenuItemnews
            setMenuItems={(value) => {
              setMenuItems(value);
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default About;
