import React from 'react';
import Navbar from './component/layout/Navbar';
import Footer from './component/layout/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Product from './pages/product';
import About from './pages/about';
import News from './pages/news';
import Infomation from './pages/infomation';
import ProductDetail from './pages/product/detail';


const App: React.FC = () => {
  return (
    <Router>
    <div className="site-wrap">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<Product />} />
        <Route path="/product/:id" element={<ProductDetail />} />
        <Route path="/about" element={<About/>}/>
        <Route path="/news" element={<News/>}/>
        <Route path="/infomation" element={<Infomation />} />
      </Routes>
      <Footer />
    </div>
  </Router>
  );
};

export default App;
