import React, { useMemo } from "react";
import { Container, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { products } from "../../component/data";

const ProductDetail: React.FC = () => {
  let { id } = useParams();

  const product = useMemo(() => {
    return products.find((product) => product.id === Number(id));
  }, [id]);

  if (!product) {
    return <Spinner animation="border" variant="success" />;
  }

  return (
    <div className="site-wrap">
      <div className="site-section">
        <Container>
          <h2 className="text-center mb-4">SẢN PHẨM</h2>
          <div
            className="content-container"
            style={{ wordWrap: "break-word", overflow: "hidden" }}
            dangerouslySetInnerHTML={{ __html: product?.content || "" }}
          />
        </Container>
      </div>
    </div>
  );
};

export default ProductDetail;
