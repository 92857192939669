import { Container, Row, Col } from "react-bootstrap";
import product01 from "../../public/images/product/product1.png";
import MenuItemnews from "../../component/menuItem/menuItemNew";
import { useState } from "react";
import { NewsType } from "../../types";

const Infomation = () => {
const [menuItems, setMenuItems] = useState<NewsType>();
  return (
    <Container className="my-5">
      <Row>
        <Col md={9}>
          <h2 className="fw-bold">Chia sẻ kiến thức</h2>
          <p className="fw-bold">
            Đông trùng hạ thảo KOVI là đơn vị tiên phong áp dụng sản xuất nông nghiệp công nghệ cao và giá trị trong sản xuất các sản phẩm nông nghiệp tại Việt Nam.
          </p>
          <p>
            Sản phẩm Đông Trùng Hạ Thảo được nuôi trồng thành công là kết quả nghiên cứu, chuyển giao công nghệ giữa Việt Nam và các nước Hàn Quốc, Nhật Bản, Trung Quốc...
          </p>
          <p>
            Chúng tôi tự hào là đơn vị sản xuất uy tín đưa ra các sản phẩm từ Đông Trùng Hạ Thảo tốt nhất. Đông trùng hạ thảo KOVI chung tay chăm sóc và bảo vệ sức khỏe cộng đồng.
          </p>
          <p className="fw-bold text-primary">
            Đông trùng hạ thảo KOVI - Cho cuộc sống khỏe vui.
          </p>

          <h3 className="fw-bold mt-5">TẦM NHÌN KOVI:</h3>
          <img src={product01} alt="Tầm Nhìn KOVI" className="img-fluid rounded" />
        </Col>

        <Col md={3} className="border-start border-1 border-muted">
          <MenuItemnews setMenuItems={(value) => setMenuItems(value)}/>
        </Col>
      </Row>
    </Container>
  );
};

export default Infomation;
