import product2 from "../../public/images/product/product2.png";
import product3 from "../../public/images/product/product3.png";
import product4 from "../../public/images/product/product4.png";
import product5 from "../../public/images/product/product5.png";
import product6 from "../../public/images/product/product6.png";
import product7 from "../../public/images/product/product7.png";
import product8 from "../../public/images/product/product8.png";
import product9 from "../../public/images/product/product9.png";

// news
import newImage from "../../public/images/new/new.png";
import newImage1 from "../../public/images/new/new1.png";
import newImage2 from "../../public/images/new/new2.png";
import newImage3 from "../../public/images/new/new3.png";
import newImage4 from "../../public/images/new/new4.png";
import { NewsType } from "../../types";

// sibar
import information from "../../public/images/new/information.png";
import information1 from "../../public/images/new/information1.png";
import information2 from "../../public/images/new/information2.png";

// knowledge
import knowledge from "../../public/images/new/knowledge.png";
import knowledge1 from "../../public/images/new/knowledge1.png";
import knowledge2 from "../../public/images/new/knowledge2.png";
import knowledge3 from "../../public/images/new/knowledge3.png";
import knowledge4 from "../../public/images/new/knowledge4.png";

export const products = [
  {
    id: 1,
    img: product2,
    price: 80000,
    name: "Đông trùng hạ thảo tươi KOVI",
    content: ` 
    	<h2 class="title_boxs"><span>Chi tiết sản phẩm: Đông trùng hạ thảo tươi KOVI</span></h2>
        <div class="ct_dep ">
    	  
                 <div class="text"><h2>
	<span style="font-size: 20px;">Giới thiệu Đông trùng hạ thảo tươi KOVI:</span></h2>
<p style="text-align: center;">
	<span style="font-size: 20px;"><img alt="Đông trùng hạ thảo tươi" src="https://i.postimg.cc/2jvFvc7C/1.png" style="height: 400px; width: 800px;" /></span></p>
<p>
	<span style="font-size: 18px;"><strong>Đông trùng hạ thảo tươi</strong> là dạng sống cộng sinh đặc biệt giữa nấm Cordyceps (thường là Cordyceps militaris hoặc Cordyceps sinensis) và ấu trùng của một số loài bướm. Do được thu hoạch trực tiếp từ môi trường tự nhiên hoặc nuôi trồng nhân tạo mà không qua chế biến sấy khô, Đông trùng hạ thảo tươi giữ nguyên vẹn các dưỡng chất quý giá, mang lại nhiều lợi ích cho sức khỏe.</span></p>
<p>
	<span style="font-size: 18px;"><strong>Đông Trùng Hạ Thảo Tươi KOVI</strong> được nuôi trồng trực tiếp trên hộp nhựa 750ml gồm thành phần Gạo Lứt, Nhộng Tằm Say, Đường Gluco, Khoai Tây, Lòng Đỏ Trứng Gà, Các loại Vitamin hòa cùng nước tạo hỗn hợp cơ chất dạng dịch lỏng. </span></p>
<p>
	<span style="font-size: 18px;">Sau khi các thành phần trên được đưa vào hộp nhựa dưới dạng hỗn hợp dịch lỏng sẽ được đưa vào nồi hấp tiệt trùng trong 30 phút ở nhiệt độ 121 độ. Tiệt trùng xong sẽ được đưa vào phòng chờ để nguội rồi tiến hành cấy giống nấm Cordyceps Militaris vào từng hộp. </span></p>
<p>
	<span style="font-size: 18px;">Qua quá trình nuôi tối 7 ngày rồi chuyển sang nuôi sáng sau 60 ngày Nấm Đông Trùng Hạ Thảo sẽ đạt dược tính cao nhất sẽ được thu hoạch xuất ra thị trường trực tiếp dưới dạng tươi hoặc sấy khô đóng gói sử dụng.</span></p>
<p style="text-align: center;">
	<span style="font-size: 20px;"><img alt="Đông trùng hạ thảo" src="https://i.postimg.cc/zGDnsJZf/2.png" style="width: 600px; height: 600px;" /><br />
	<span style="display: none;">&nbsp;</span></span></p>
<h3>
	<span style="font-size: 20px;">Đặc điểm hình thái:</span></h3>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Phần nấm:</span></p>
		<ul>
			<li aria-level="2" dir="ltr">
				<p>
					<span style="font-size: 18px;">Màu vàng nâu hoặc vàng cam, hình dạng thon dài, mọc ra từ đầu ấu trùng.</span></p>
			</li>
			<li aria-level="2" dir="ltr">
				<p>
					<span style="font-size: 18px;">Bề mặt nhẵn mịn, có các đường vân dọc theo chiều dài.</span></p>
			</li>
			<li aria-level="2" dir="ltr">
				<p>
					<span style="font-size: 18px;">Chiều dài trung bình từ 4 đến 10 cm, đường kính khoảng 0,5 đến 1 cm.</span></p>
			</li>
		</ul>
	</li>
</ul>
<h3>
	<span style="font-size: 20px;">Đặc điểm sinh học:</span></h3>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Môi trường sống:</span></p>
		<ul>
			<li aria-level="2" dir="ltr">
				<p>
					<span style="font-size: 18px;"><strong>Đông trùng hạ thảo tươi </strong>được tìm thấy ở những vùng núi cao, có khí hậu lạnh và ẩm ướt, đặc biệt là ở khu vực Tây Tạng, Trung Quốc và Bhutan.</span></p>
			</li>
			<li aria-level="2" dir="ltr">
				<p>
					<span style="font-size: 18px;">Nuôi trồng nhân tạo: Hiện nay, <strong>Đông trùng hạ thảo tươi</strong> cũng được nuôi trồng nhân tạo trong môi trường được kiểm soát chặt chẽ để đảm bảo chất lượng và sản lượng.</span></p>
			</li>
		</ul>
	</li>
</ul>
<p style="text-align: center;">
	&nbsp;<img alt="Đặc điểm đông trùng hạ thảo" src="https://i.postimg.cc/YCjvNjBQ/3.jpg" style="height: 400px; width: 600px;" />&nbsp;</p>
<h3 style="text-align: center;">
	<em><span style="font-size: 16px;">Hộp nấm đông trùng hạ thảo tươi KOVI</span></em></h3>
<h3>
	<span style="font-size: 20px;">Thành phần dinh dưỡng:</span></h3>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;"><strong>Đông trùng hạ thảo tươi KOVI</strong> chứa hàm lượng cao các dưỡng chất quý giá như <strong><u><a href="cordycepin-la-gi.html">cordycepin</a></u></strong> &gt;= 2, <u><strong><a href="adenosine-la-gi-.html">adenosine</a></strong></u> &gt;= 1,5, axit amin, vitamin B1, B2, B12, C, E,...</span></p>
	</li>
</ul>
<h2>
	<span style="font-size: 22px;">Công dụng của Đông trùng hạ thảo tươi KOVI&nbsp;</span></h2>
<p style="text-align: center;">
	&nbsp;&nbsp;&nbsp;<img alt="Tác dụng đông trùng hạ thảo" src="https://i.postimg.cc/QNbCRcXp/4.png" style="height: 990px; width: 700px;" /></p>
<p style="text-align: center;">
	&nbsp;</p>
<p style="text-align: center;">
	<span style="font-size: 20px;"><span style="display: none;">&nbsp;</span><span style="display: none;">&nbsp;</span><span style="display: none;">&nbsp;</span></span></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Phục hồi sức khỏe nhanh, tăng cường hệ miễn dịch.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Chống lão hóa</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Cải thiện sức khỏe tim mạch hỗ trợ điều trị và ngăn ngừa ung thư, đột quỵ.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Tăng cường hoạt động chức năng gan, thận, phổi.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Cải thiện chất lượng giấc ngủ, giúp ngủ ngon.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Hỗ trợ giải rượu, giúp cân bằng nội tiết tố.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Giúp bồi bổ cơ thể ăn ngon miệng, hỗ trợ tăng cường sức khỏe.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Giảm căng thẳng, mệt mỏi</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Nâng cao sức đề kháng, giảm suy nhược cơ thể.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Tăng cường sinh lý nam nữ</span></p>
	</li>
</ul>
<h2>
	<span style="font-size: 22px;">Một hộp Đông trùng hạ thảo KOVI có trọng lượng như nào</span></h2>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;"><strong>Đông trùng hạ thảo tươi KOVI</strong> được nuôi trong hộp nhựa dung tích 750ml trọng lượng khoảng 200g, trong đó phần thảo và đế khoảng 150g.</span></p>
	</li>
</ul>
<p style="text-align: center;">
	&nbsp;&nbsp;<img alt="Trọng lượng đông trùng hạ thảo" src="https://i.postimg.cc/SNPShrHz/5.png" style="height: 600px; width: 600px;" />&nbsp;</p>
<h2>
	<span style="font-size: 22px;">Đông trùng hạ thảo tươi KOVI dành cho những ai&nbsp;</span></h2>
<p style="text-align: center;">
	&nbsp;<img alt="Đối tượng sử dụng đông trùng hạ thảo" src="https://i.postimg.cc/sDCF9LFm/6.jpg" style="height: 400px; width: 600px;" /></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Người cao tuổi cần tăng cường sức khỏe.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Người bị suy nhược cơ thể, người mới ốm dậy.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Phụ nữ sau sinh cần phục hồi sức khỏe và tiết sữa tốt.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Vận động viên, gymer, người lao động nặng nhọc.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Nam, nữ cần cải thiện sức khỏe sinh lý.</span></p>
	</li>
</ul>
<h2>
	<span style="font-size: 22px;">Cách sử dụng đông trùng hạ thảo tươi KOVI&nbsp;</span></h2>
<h3>
	<span style="font-size: 20px;">Đông trùng hạ thảo tươi KOVI pha trà:</span></h3>
<p>
	<span style="font-size: 18px;">Cho từ 15-20 (khoảng 1-2g) sợi đông trùng hạ thảo vào bình giữ nhiệt. Cho khoảng 1 lít nước 70-80 độ vào bình giữ nhiệt, sau 20-30 phút là sử dụng được. Có thể cho thêm mật ong hoặc táo đỏ để tăng hương vị và dưỡng chất. Nên uống trà đông trùng hạ thảo vào buổi sáng hoặc sau bữa ăn.</span></p>
<p style="text-align: center;">
	<a class="emyt" data-height="450" data-id="KBMWkd2SCbg" data-width="800"><img border="0" height="450" src="https://dongtrungkovi.vn/ckeditor/plugins/youtube/images/playyoutube.png" style="background: url(https://i.ytimg.com/vi/KBMWkd2SCbg/hqdefault.jpg) center center black no-repeat;background-size:100%" width="800" /></a><a class="emyt" data-height="450" data-id="KBMWkd2SCbg" data-width="800">&nbsp;</a>&nbsp;&nbsp;</p>
<h3>
	<span style="font-size: 20px;">Đông trùng hạ thảo tươi KOVI ngâm mật ong:</span></h3>
<p>
	<span style="font-size: 18px;">Cho khoảng 20g đông trùng hạ thảo ngâm với 1 lít mật ong trong lọ thủy tinh, đổ mật ong ngập mặt đông trùng hạ thảo, đậy nắp kín, giữ trong 20 ngày đến 1 tháng là có thể sử dụng.</span></p>
<p>
	<span style="font-size: 18px;">Cách dùng: Mỗi lần lấy ra 20 đến 30ml mật ong đông trùng hạ thảo pha với nước ấm uống mỗi sáng trước khi ăn, ngày một lần.</span></p>
<p style="text-align: center;">
	<a class="emyt" data-height="450" data-id="Qc7T_EDeJig" data-width="800"><img border="0" height="450" src="https://dongtrungkovi.vn/ckeditor/plugins/youtube/images/playyoutube.png" style="background: url(https://i.ytimg.com/vi/Qc7T_EDeJig/hqdefault.jpg) center center black no-repeat;background-size:100%" width="800" /></a><a class="emyt" data-height="450" data-id="Qc7T_EDeJig" data-width="800">&nbsp;</a>&nbsp;</p>
<h3>
	<span style="font-size: 20px;">Đông trùng hạ thảo tươi KOVI ngâm rượu:</span></h3>
<p>
	<span style="font-size: 18px;">Chúng ta ngâm theo theo tỷ lệ 10g (2 hũ tươi) với 1 lít rượu ngâm sau 15 ngày là sử dụng được.&nbsp;</span></p>
<p>
	<span style="font-size: 18px;">·&nbsp; Ngâm 10-20g đông trùng hạ thảo với 1 lít rượu trắng trong khoảng 1 tháng.</span></p>
<p>
	<span style="font-size: 18px;">·&nbsp; Sau đó, có thể dùng rượu để uống trực tiếp hoặc pha loãng với nước.</span></p>
<p>
	<span style="font-size: 18px;">·&nbsp; Nên uống rượu đông trùng hạ thảo trước bữa ăn 30 phút.</span></p>
<p style="text-align: center;">
	<a class="emyt" data-height="450" data-id="X0Pob413QXI" data-width="800"><img border="0" height="450" src="https://dongtrungkovi.vn/ckeditor/plugins/youtube/images/playyoutube.png" style="background: url(https://i.ytimg.com/vi/X0Pob413QXI/hqdefault.jpg) center center black no-repeat;background-size:100%" width="800" /></a><a class="emyt" data-height="450" data-id="X0Pob413QXI" data-width="800">&nbsp;</a>&nbsp;</p>
<h3>
	<span style="font-size: 20px;">Đông trùng hạ thảo tươi KOVI hầm gà:</span></h3>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Cho 1-2g đông trùng hạ thảo vào nồi gà hầm, hầm trong khoảng 1-2 tiếng.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Có thể cho thêm các loại thảo mộc khác như nhân sâm, đương quy,... để tăng cường tác dụng.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Nên ăn gà hầm đông trùng hạ thảo 1-2 lần mỗi tuần.</span></p>
	</li>
</ul>
<p style="text-align: center;">
	&nbsp;&nbsp;<img alt="hầm gà đông trùng hạ thảo" src="https://i.postimg.cc/zfB4GZWz/7.jpg" style="height: 399px; width: 600px;" />&nbsp;</p>
<h3>
	<span style="font-size: 20px;">Đông trùng hạ thảo tươi KOVI nấu cháo</span></h3>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Cho 1-2g đông trùng hạ thảo vào nồi cháo, nấu chín rồi thưởng thức.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Có thể cho thêm các loại hạt dinh dưỡng như óc chó, hạnh nhân,... để tăng cường giá trị dinh dưỡng.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Nên ăn cháo đông trùng hạ thảo vào buổi sáng hoặc bữa tối.</span></p>
	</li>
</ul>
<p style="text-align: center;">
	&nbsp;&nbsp;<img alt="Cháo đông trùng hạ thảo" src="https://i.postimg.cc/k5BhdN3D/8.png" style="height: 400px; width: 600px;" /></p>
<p style="text-align: center;">
	<span style="font-size: 20px;"><span style="display: none;">&nbsp;</span></span></p>
<h3>
	<span style="font-size: 20px;">Đông trùng hạ thảo tươi KOVI nấu cháo, súp, hầm gà, hầm chim</span></h3>
<p>
	<span style="font-size: 18px;">Đồ ăn được nấu chín trên bếp sau đó cho 15-20 sợi Đông Trùng Hạ Thảo vào sau 3-5 phút bắt đầu sử dụng.</span></p>
<p>
	<span style="font-size: 18px;">Một đợt dùng từ 3-6 tháng để có công dụng tốt nhất. Có thể liên tục được như một loại thực phẩm bổ sung rất tốt cho sức khoẻ</span></p>
<p>
	<span style="font-size: 20px;">&nbsp;</span></p>
<p style="text-align: center;">
	&nbsp;<img alt="Súp đông trùng hạ thảo" src="https://i.postimg.cc/3R9yCGNC/8.png" style="height: 375px; width: 600px;" />&nbsp;</p>
<p>
	<span style="font-size: 20px;">&nbsp;</span></p>
<p>
	<span style="font-size: 20px;">Lưu ý khi&nbsp; bảo quản</span></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Bảo quản trong môi trường thoáng mát như tủ lạnh hoặc tủ đông</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Tránh ánh nắng trực tiếp&nbsp;</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Đông trùng hạ thảo tươi có thời gian sử dụng ngắn hạn</span></p>
	</li>
</ul>
<h2>
	<span style="font-size: 22px;">Đánh giá Đông trùng hạ thảo tươi KOVI</span></h2>
<p style="text-align: center;">
	<span style="font-size: 20px;"><img alt="Đánh giá đông trùng hạ thảo" src="https://i.postimg.cc/2jcb1nPC/9.png" style="width: 800px; height: 800px;" /></span></p>
<p>
	<span style="font-size: 18px;">Để đánh giá <strong>Đông trùng hạ thảo tươi KOVI</strong> có tốt không chúng ta sẽ cần xem xét đến các yếu tố như chủng giống nấm, quy trình sản xuất và tính minh bạch của hồ sơ chứng nhận chất lượng, cũng như đặc tính về thẩm mĩ của sản phẩm.&nbsp;</span><span style="font-size: 18px;">Câu hỏi </span><strong style="font-size: 18px;">Đông trùng hạ thảo tươi KOVI</strong><span style="font-size: 18px;"> có tốt không được trả lời bằng tính minh bạch của các yếu tố như sau:&nbsp;</span></p>
<p>
	<span style="font-size: 18px;">Quy trình sản xuất Đông trùng hạ thảo (nuôi cấy nhân tạo) bao gồm nhiều giai đoạn phức tạp và đòi hỏi kỹ thuật cao để đảm bảo chất lượng sản phẩm. Dưới đây là các bước cơ bản trong quy trình sản xuất <strong>Đông trùng hạ thảo KOVI</strong>:</span></p>
<p>
	<span style="font-size: 18px;"><strong>1. Chuẩn bị nguyên liệu:</strong></span></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;"><strong>Giống nấm Cordyceps militaris</strong>: Giống nấm phải được tuyển chọn từ các nguồn uy tín, đảm bảo chất lượng và khả năng sinh trưởng tốt.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Môi trường nuôi cấy: Môi trường nuôi cấy phải được khử trùng sạch sẽ và đảm bảo điều kiện nhiệt độ, độ ẩm, ánh sáng phù hợp cho sự phát triển của nấm.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Nước dinh dưỡng: Nước dinh dưỡng được sử dụng để cung cấp dinh dưỡng cho nấm phát triển. Nước dinh dưỡng thường được pha chế từ các nguyên liệu như gạo lứt, nhộng tằm, nấm men,...</span></p>
	</li>
</ul>
<p>
	<span style="font-size: 18px;"><strong>2. Xử lý nguyên liệu:</strong></span></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;"><strong>Giống nấm Cordyceps militaris</strong>: Giống nấm được cấy vào môi trường thạch dinh dưỡng để kích thích sinh trưởng và phát triển.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Môi trường nuôi cấy: Môi trường nuôi cấy được khử trùng bằng phương pháp tiệt trùng hoặc hun khói để loại bỏ vi khuẩn, nấm mốc.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Nước dinh dưỡng: Nước dinh dưỡng được khử trùng bằng phương pháp đun sôi hoặc lọc qua màng lọc vi sinh.</span></p>
	</li>
</ul>
<p>
	<span style="font-size: 18px;"><strong>3. Cấy giống nấm:</strong></span></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;"><strong>Giống nấm Cordyceps militaris</strong> được cấy vào môi trường nuôi cấy đã được khử trùng.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Quá trình cấy giống nấm phải được thực hiện trong điều kiện vô trùng để tránh nhiễm vi khuẩn.</span></p>
	</li>
</ul>
<p>
	<span style="font-size: 18px;"><strong>4. Nuôi cấy:</strong></span></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Môi trường nuôi cấy được điều chỉnh nhiệt độ, độ ẩm và ánh sáng phù hợp cho sự phát triển của nấm.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Nấm được cung cấp nước dinh dưỡng thường xuyên để đảm bảo đủ dinh dưỡng cho phát triển.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Quá trình nuôi cấy thường kéo dài từ 30 đến 45 ngày.</span></p>
	</li>
</ul>
<p>
	<span style="font-size: 18px;"><strong>5. Thu hoạch:</strong></span></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Khi nấm <strong>Cordyceps militaris</strong> phát triển đầy đủ, nấm được thu hoạch bằng tay hoặc bằng máy móc tự động.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Nấm được thu hoạch phải được xử lý cẩn thận để tránh làm hỏng.</span></p>
	</li>
</ul>
<p>
	<span style="font-size: 18px;"><strong>6. Kiểm tra chất lượng:</strong></span></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;"><strong>Đông trùng hạ thảo tươi</strong> được kiểm tra chất lượng để đảm bảo an toàn và hiệu quả sử dụng.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Kiểm tra chất lượng bao gồm kiểm tra hàm lượng dinh dưỡng, kiểm tra vi sinh, kiểm tra kim loại nặng,...</span></p>
	</li>
</ul>
<p>
	<span style="font-size: 18px;"><strong>7. Đóng gói và bảo quản:</strong></span></p>
<p>
	<span style="font-size: 18px;"><strong>Đông trùng hạ thảo tươi KOVI</strong> được đóng gói trong hộp nhựa 750ml và bảo quản trong tủ lạnh hoặc tủ đông.</span></p>
<p>
	<span style="font-size: 18px;">Lưu ý khi bảo quản:</span></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Bảo quản trong môi trường thoáng mát như tủ lạnh hoặc tủ đông</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Tránh ánh nắng trực tiếp&nbsp;</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Đông trùng hạ thảo tươi có thời gian sử dụng ngắn hạn</span></p>
	</li>
</ul>
<p>
	<span style="font-size: 18px;">Ưu điểm của <strong>Đông trùng hạ thảo tươi KOVI</strong>:</span></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Đảm bảo vệ sinh an toàn thực phẩm.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Loại bỏ nguy cơ nhiễm vi khuẩn, nấm mốc từ sâu.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Kiểm soát được chất lượng sản phẩm tốt hơn.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Nâng cao năng suất sản xuất.</span></p>
	</li>
</ul>
<p>
	<span style="font-size: 22px;"><em><strong>Đông trùng hạ thảo KOVI - Cho cuộc sống khỏe vui.</strong></em></span></p>
</div></div></div>`,
  },
  {
    id: 2,
    img: product3,
    price: 390000,
    name: "Đông trùng hạ thảo khô sấy thăng hoa KOVI 15g",
    content: `
    	<h2 class="title_boxs"><span>Chi tiết sản phẩm: Đông trùng hạ thảo khô sấy thăng hoa KOVI 15g</span></h2>
        <div class="ct_dep "><div class="text"><h2>
	<span style="font-size: 22px;">Đông trùng hạ thảo khô sấy thăng hoa KOVI: Bí ẩn gì ẩn sau cái tên:</span></h2>
<p dir="ltr" style="text-align: center;">
	<span style="font-size: 20px;"><img alt="Đông trùng hạ thảo khô sấy thăng hoa" src="https://i.postimg.cc/wBRR7RF4/12.png" style="height: 400px; width: 800px;" /></span></p>
<p dir="ltr" style="text-align: justify;">
	&nbsp;</p>
<p dir="ltr" style="text-align: justify;">
	<span style="font-size: 20px;">Đông trùng hạ thảo vốn được mệnh danh là &quot; vị thuốc quý &quot; bởi những công dụng tuyệt vời cho sức khỏe. Tuy nhiên, để lưu giữ trọn vẹn dưỡng chất quý giá từ thiên nhiên, phương pháp sấy thăng hoa ra đời, mở ra kỷ nguyên mới cho Đông trùng hạ thảo.</span></p>
<p dir="ltr" style="text-align: justify;">
	&nbsp;</p>
<p style="text-align: center;">
	<span style="display: none;">&nbsp;</span></p>
<p dir="ltr" style="text-align: center;">
	<img alt="Đông trùng hạ thảo sấy thăng hoa" src="https://i.postimg.cc/Px8bsFBx/11.jpg" style="height: 600px; width: 600px;" /></p>
<p>
	<span style="display: none;">&nbsp;</span></p>
<h3 style="text-align: center;">
	<em><span style="font-size: 16px;">Sấy thăng hoa - Công nghệ bảo toàn &quot; tinh hoa &quot;&nbsp;</span></em></h3>
<p dir="ltr" style="text-align: justify;">
	<span style="font-size: 18px;">Khác với các phương pháp sấy thông thường, sấy thăng hoa diễn ra trong môi trường chân không ở nhiệt độ thấp. Nước trong Đông trùng hạ thảo được chuyển trực tiếp từ trạng thái rắn sang hơi, giữ nguyên cấu trúc và chất dinh dưỡng. Nhờ vậy, <strong>Đông trùng hạ thảo khô sấy thăng hoa KOVI</strong> <strong>15g</strong>&nbsp;sở hữu những ưu điểm vượt trội:</span></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Bảo toàn dưỡng chất: Hơn 90% vitamin, khoáng chất, axit amin và các hợp chất quý hiếm như Cordycepin &amp; Adenosine được lưu giữ nguyên vẹn.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Giữ nguyên hương vị: Vị thơm đặc trưng của Đông trùng hạ thảo được bảo toàn, mang đến trải nghiệm trọn vẹn.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Tăng thời hạn sử dụng: Đông trùng hạ thảo khô sấy thăng hoa có thể bảo quản trong thời gian dài mà không lo biến chất, nấm mốc.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Tiện lợi sử dụng: Dễ dàng pha trà, nấu cháo, hầm canh hoặc nghiền bột để sử dụng.</span></p>
	</li>
</ul>
<h3>
	<span style="font-size: 20px;">Đặc biệt, để sản xuất Đông trùng hạ thảo khô sấy thăng hoa KOVI 15g thì cần phải có:</span></h3>
<ul>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Chi phí đầu tư cao hơn so với quy trình sản xuất truyền thống.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Yêu cầu kỹ thuật cao hơn.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Sản lượng có thể thấp hơn so với quy trình sản xuất truyền thống.</span></p>
	</li>
</ul>
<h2>
	<span style="font-size: 22px;">Công dụng của Đông trùng hạ thảo khô sấy thăng hoa KOVI 15g</span></h2>
<p dir="ltr" style="text-align: center;">
	<span style="font-size: 20px;"><img alt="Tác dụng của đông trùng hạ thảo khô sấy thăng hoa" src="https://i.postimg.cc/jj3vrf28/11.png" style="height: 990px; width: 700px;" /></span></p>
<p dir="ltr">
	&nbsp;</p>
<ul>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Phục hồi sức khỏe nhanh, tăng cường hệ miễn dịch.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Chống lão hóa</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Cải thiện sức khỏe tim mạch hỗ trợ điều trị và ngăn ngừa ung thư, đột quỵ.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Tăng cường hoạt động chức năng gan, thận, phổi.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Cải thiện chất lượng giấc ngủ, giúp ngủ ngon.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Hỗ trợ giải rượu, giúp cân bằng nội tiết tố.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Giúp bồi bổ cơ thể ăn ngon miệng, hỗ trợ tăng cường sức khoẻ.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Giảm căng thẳng, mệt mỏi</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Nâng cao sức đề kháng, giảm suy nhược cơ thể.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Tăng cường sinh lý nam nữ.</span></p>
	</li>
</ul>
<h2>
	<span style="font-size: 22px;">Thành phần của đông trùng hạ thảo khô sấy thăng hoa KOVI 15g</span></h2>
<ul>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Sợi đông trùng hạ thảo 100%</span></p>
	</li>
</ul>
<h2>
	<span style="font-size: 22px;">Đông trùng hạ thảo sấy thăng hoa KOVI 15g dành cho những ai</span></h2>
<p dir="ltr" style="text-align: center;">
	&nbsp;&nbsp;<img alt="Đối tượng sử dụng đông trùng hạ thảo" src="https://i.postimg.cc/sDCF9LFm/6.jpg" style="height: 400px; width: 600px;" />&nbsp;&nbsp;&nbsp;</p>
<ul>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Người cao tuổi cần tăng cường sức khỏe.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Người bị suy nhược cơ thể, người mới ốm dậy.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Phụ nữ sau sinh cần phục hồi sức khỏe và tiết sữa tốt.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Vận động viên, gymer, người lao động nặng nhọc.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Nam, nữ cần cải thiện sức khỏe sinh lý.</span></p>
	</li>
</ul>
<h2>
	<span style="font-size: 22px;">Cách sử dụng đông trùng hạ thảo khô sấy thăng hoa KOVI 15g</span></h2>
<p dir="ltr" style="text-align: justify;">
	&nbsp;</p>
<h3 style="text-align: justify;">
	<span style="font-size: 20px;">Đông trùng hạ thảo khô sấy thăng hoa 15g pha trà:</span></h3>
<p style="text-align: justify;">
	<span style="font-size: 18px;">Cho từ 15-20 (khoảng 1-2g) sợi thảo vào bình giữ nhiệt. Cho khoảng 1 lít nước 70-80 độ vào bình giữ nhiệt, sau 20-30 phút là sử dụng được. Có thể cho thêm mật ong hoặc táo đỏ để tăng hương vị và dưỡng chất. Nên uống trà đông trùng hạ thảo vào buổi sáng hoặc sau bữa ăn.</span></p>
<p dir="ltr" style="text-align: center;">
	<a class="emyt" data-height="450" data-id="KBMWkd2SCbg" data-width="800"> <img border="0" height="450" src="https://dongtrungkovi.vn/ckeditor/plugins/youtube/images/playyoutube.png" style="background: url(https://i.ytimg.com/vi/KBMWkd2SCbg/hqdefault.jpg) center center black no-repeat;background-size:100%" width="800" /></a></p>
<p dir="ltr" style="text-align: center;">
	&nbsp;</p>
<h3 dir="ltr" style="text-align: justify;">
	<span style="font-size: 20px;">Đông trùng hạ thảo khô sấy thăng hoa 15g ngâm mật ong:</span></h3>
<p style="text-align: justify;">
	<span style="font-size: 18px;">Cho khoảng 20g đông trùng khô ngâm với 1 lít mật ong trong lọ thủy tinh, đổ mật ong ngập mặt đông trùng, đậy nắp kín, giữ trong 20 ngày đến 1 tháng là có thể sử dụng.</span></p>
<p style="text-align: justify;">
	<span style="font-size: 18px;">Cách dùng: Mỗi lần lấy ra 20 đến 30ml mật ong đông trùng hạ thảo pha với nước ấm uống mỗi sáng trước khi ăn, ngày một lần.</span></p>
<p dir="ltr" style="text-align: center;">
	<a class="emyt" data-height="450" data-id="Qc7T_EDeJig" data-width="800"> <img border="0" height="450" src="https://dongtrungkovi.vn/ckeditor/plugins/youtube/images/playyoutube.png" style="background: url(https://i.ytimg.com/vi/Qc7T_EDeJig/hqdefault.jpg) center center black no-repeat;background-size:100%" width="800" /></a></p>
<p dir="ltr" style="text-align: center;">
	&nbsp;</p>
<h3 dir="ltr" style="text-align: justify;">
	<span style="font-size: 20px;">Đông trùng hạ thảo khô sấy thăng hoa 15g ngâm rượu:</span></h3>
<p dir="ltr" style="text-align: justify;">
	<span style="font-size: 18px;">Chúng ta ngâm theo theo tỷ lệ 10g khô (2 hũ tươi) với 1 lít rượu ngâm sau 15 ngày là sử dụng được.&nbsp;</span></p>
<p dir="ltr" style="text-align: justify;">
	<span style="font-size: 18px;">·&nbsp; Ngâm 10-20g đông trùng hạ thảo với 1 lít rượu trắng trong khoảng 1 tháng.</span></p>
<p dir="ltr" style="text-align: justify;">
	<span style="font-size: 18px;">·&nbsp; Sau đó, có thể dùng rượu để uống trực tiếp hoặc pha loãng với nước.</span></p>
<p dir="ltr" style="text-align: justify;">
	<span style="font-size: 18px;">·&nbsp; Nên uống rượu đông trùng hạ thảo trước bữa ăn 30 phút.</span></p>
<p dir="ltr" style="text-align: center;">
	<a class="emyt" data-height="450" data-id="X0Pob413QXI" data-width="800"> <img border="0" height="450" src="https://dongtrungkovi.vn/ckeditor/plugins/youtube/images/playyoutube.png" style="background: url(https://i.ytimg.com/vi/X0Pob413QXI/hqdefault.jpg) center center black no-repeat;background-size:100%" width="800" /></a></p>
<p dir="ltr" style="text-align: center;">
	&nbsp;</p>
<h3 style="text-align: justify;">
	<span style="font-size: 20px;">Đông trùng hạ thảo khô sấy thăng hoa 15g hầm gà:</span></h3>
<ul>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Cho 1-2g ĐTHT vào nồi gà hầm, hầm trong khoảng 1-2 tiếng.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Có thể cho thêm các loại thảo mộc khác như nhân sâm, đương quy,... để tăng cường tác dụng.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Nên ăn gà hầm ĐTHT 1-2 lần mỗi tuần.</span></p>
	</li>
</ul>
<p style="text-align: center;">
	<span style="font-size: 20px;">&nbsp;<img alt="Hầm gà đông trùng hạ thảo" src="https://i.postimg.cc/zfB4GZWz/7.jpg" style="height: 399px; width: 600px;" /></span></p>
<p dir="ltr" role="presentation" style="text-align: justify;">
	<span style="font-size: 20px;">&nbsp;</span></p>
<h3 style="text-align: justify;">
	<span style="font-size: 20px;">Đông trùng hạ thảo khô sấy thăng hoa 15g nấu cháo:</span></h3>
<ul>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Cho 1-2g ĐTHT vào nồi cháo, nấu chín rồi thưởng thức.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Có thể cho thêm các loại hạt dinh dưỡng như óc chó, hạnh nhân,... để tăng cường giá trị dinh dưỡng.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Nên ăn cháo ĐTHT vào buổi sáng hoặc bữa tối.</span></p>
	</li>
</ul>
<p dir="ltr" style="text-align: center;">
	<span style="font-size: 20px;">&nbsp;<img alt="nấu cháo đông trùng hạ thảo" src="https://i.postimg.cc/k5BhdN3D/8.png" style="height: 400px; width: 600px;" /></span></p>
<p dir="ltr" style="text-align: center;">
	&nbsp;</p>
<h3 dir="ltr" style="text-align: justify;">
	<span style="font-size: 20px;">Đông trùng hạ thảo khô sấy thăng hoa 15g nấu cháo, súp, hầm gà, hầm chim:</span></h3>
<p dir="ltr" style="text-align: justify;">
	<span style="font-size: 18px;">Đồ ăn được nấu chín trên bếp sau đó cho 15-20 sợi Đông Trùng Hạ Thảo vào sau 3-5 phút bắt đầu sử dụng.</span></p>
<p dir="ltr" style="text-align: justify;">
	<span style="font-size: 18px;">Một đợt dùng từ 3-6 tháng để có công dụng tốt nhất. Có thể liên tục được như một loại thực phẩm bổ sung rất tốt cho sức khoẻ</span></p>
<p dir="ltr" style="text-align: justify;">
	<span style="font-size: 20px;">&nbsp;</span></p>
<p style="text-align: center;">
	<span style="font-size: 20px;"><img alt="súp đông trùng hạ thảo" src="https://i.postimg.cc/3R9yCGNC/8.png" style="height: 375px; width: 600px;" /></span></p>
<h2>
	<span style="font-size: 22px;">Đánh giá <strong>đông trùng hạ thảo khô sấy thăng hoa KOVI</strong> 15g</span></h2>
<p dir="ltr" style="text-align: justify;">
	<span style="font-size: 18px;">Để đánh giá <strong>Đông trùng hạ thảo khô sấy thăng hoa KOVI</strong> <strong>15g</strong>&nbsp;có tốt không chúng ta sẽ cần xem xét đến các yếu tố như chủng giống nấm, quy trình sản xuất và tính minh bạch của hồ sơ chứng nhận chất lượng, cũng như đặc tính về thẩm mĩ của sản phẩm. Câu hỏi <strong>Đông trùng hạ thảo khô sấy thăng hoa KOVI 15g</strong>&nbsp;có tốt không được trả lời bằng tính minh bạch của các yếu tố như sau:</span></p>
<p dir="ltr" style="text-align: justify;">
	<span style="font-size: 18px;">&nbsp;</span></p>
<p dir="ltr" style="text-align: center;">
	<span style="font-size: 20px;">&nbsp;<img alt="đánh giá đông trùng hạ thảo khô sấy thăng hoa" src="https://i.postimg.cc/WpHD63ZG/10.jpg" style="height: 800px; width: 800px;" /></span></p>
<p dir="ltr" style="text-align: justify;">
	&nbsp;</p>
<p dir="ltr" style="text-align: justify;">
	<span style="font-size: 18px;">Quy trình sản xuất <strong>đông trùng hạ thảo khô sấy thăng hoa KOVI 15g</strong>&nbsp;bao gồm nhiều giai đoạn phức tạp và đòi hỏi kỹ thuật cao để đảm bảo chất lượng sản phẩm. Dưới đây là các bước cơ bản trong quy trình sản xuất <strong>Đông trùng hạ thảo khô sấy thăng hoa KOVI 15g</strong>:</span></p>
<p dir="ltr" style="text-align: justify;">
	<strong><span style="font-size: 20px;">1. Chuẩn bị nguyên liệu:</span></strong></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;"><strong>Giống nấm</strong> <strong>Cordyceps militaris</strong>: Giống nấm phải được tuyển chọn từ các nguồn uy tín, đảm bảo chất lượng và khả năng sinh trưởng tốt.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;"><strong>Môi trường nuôi cấy: </strong>Môi trường nuôi cấy phải được khử trùng sạch sẽ và đảm bảo điều kiện nhiệt độ, độ ẩm, ánh sáng phù hợp cho sự phát triển của nấm.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;"><strong>Nước dinh dưỡng:</strong> Nước dinh dưỡng được sử dụng để cung cấp dinh dưỡng cho nấm phát triển. Nước dinh dưỡng thường được pha chế từ các nguyên liệu như gạo lứt, nhộng tằm, nấm men, trứng gà, khoai tây ...</span></p>
	</li>
</ul>
<p dir="ltr" style="text-align: justify;">
	<strong><span style="font-size: 20px;">2. Xử lý nguyên liệu:</span></strong></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;"><strong>Giống nấm Cordyceps militaris</strong>: Giống nấm được cấy vào môi trường thạch dinh dưỡng để kích thích sinh trưởng và phát triển.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;"><strong>Môi trường nuôi cấy:</strong> Môi trường nuôi cấy được khử trùng bằng phương pháp tiệt trùng hoặc hun khói để loại bỏ vi khuẩn, nấm mốc.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;"><strong>Nước dinh dưỡng:</strong> Nước dinh dưỡng được khử trùng bằng phương pháp đun sôi hoặc lọc qua màng lọc vi sinh.</span></p>
	</li>
</ul>
<p dir="ltr" style="text-align: justify;">
	<strong><span style="font-size: 20px;">3. Cấy giống nấm:</span></strong></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Giống nấm<strong> Cordyceps militaris</strong> được cấy vào môi trường nuôi cấy đã được khử trùng.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Quá trình cấy giống nấm phải được thực hiện trong điều kiện vô trùng để tránh nhiễm vi khuẩn.</span></p>
	</li>
</ul>
<p dir="ltr" style="text-align: justify;">
	<strong><span style="font-size: 20px;">4. Nuôi cấy:</span></strong></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Môi trường nuôi cấy được điều chỉnh nhiệt độ, độ ẩm và ánh sáng phù hợp cho sự phát triển của nấm.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Nấm được cung cấp nước dinh dưỡng thường xuyên để đảm bảo đủ dinh dưỡng cho phát triển.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Quá trình nuôi cấy thường kéo dài từ 30 đến 45 ngày.</span></p>
	</li>
</ul>
<p dir="ltr" style="text-align: justify;">
	<strong><span style="font-size: 20px;">5. Thu hoạch:</span></strong></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Khi nấm <strong>Cordyceps militaris</strong> phát triển đầy đủ, nấm được thu hoạch bằng tay hoặc bằng máy móc tự động.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Nấm được thu hoạch phải được xử lý cẩn thận để tránh làm hỏng.</span></p>
	</li>
</ul>
<p dir="ltr" style="text-align: justify;">
	<strong><span style="font-size: 20px;">6. Sấy khô:</span></strong></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Nấm thu hoạch được sấy khô bằng phương pháp sấy thăng hoa hoặc sấy lạnh để bảo quản chất lượng.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Phương pháp sấy thăng hoa là phương pháp sấy hiện đại nhất hiện nay, giúp giữ nguyên được hầu hết các chất dinh dưỡng và dược tính quý hiếm của Đông trùng hạ thảo.</span></p>
	</li>
</ul>
<p dir="ltr" style="text-align: justify;">
	<strong><span style="font-size: 20px;">7. Kiểm tra chất lượng:</span></strong></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Đông trùng hạ thảo sấy khô được kiểm tra chất lượng để đảm bảo an toàn và hiệu quả sử dụng.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Kiểm tra chất lượng bao gồm kiểm tra hàm lượng dinh dưỡng, kiểm tra vi sinh, kiểm tra kim loại nặng,...</span></p>
	</li>
</ul>
<p dir="ltr" style="text-align: justify;">
	<strong><span style="font-size: 20px;">8. Đóng gói và bảo quản:</span></strong></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p dir="ltr" role="presentation" style="text-align: justify;">
			<span style="font-size: 18px;">Đông trùng hạ thảo sấy khô được bao gói cẩn thận và bảo quản ở nơi khô ráo, thoáng mát.</span></p>
	</li>
</ul>
<p dir="ltr" style="text-align: justify;">
	<span style="font-size: 18px;">&nbsp;</span></p>
<p dir="ltr" style="text-align: justify;">
	<strong><span style="font-size: 20px;">Đông trùng hạ thảo KOVI - Cho cuộc sống khỏe vui.</span></strong></p>
<div>
</div>
</div></div></div>`,
  },
  {
    id: 3,
    img: product4,
    price: 600000,
    name: "Đông trùng hạ thảo khô sấy thăng hoa KOVI 25g",
  },
  {
    id: 4,
    img: product5,
    price: 300000,
    name: "Đông trùng hạ thảo khô sấy thăng hoa KOVI 30g",
  },
  {
    id: 5,
    img: product6,
    price: 800000,
    name: "Đông trùng hạ thảo khô sấy thăng hoa KOVI 50g",
  },
  {
    id: 6,
    img: product7,
    price: 650000,
    name: "Viên nang đông trùng hạ thảo KOVI",
  },
  {
    id: 7,
    img: product8,
    price: 1500000,
    name: "Đông trùng hạ thảo ký chủ nhộng tằm cao cấp KOVI",
  },
  {
    id: 8,
    img: product9,
    price: 1500000,
    name: "Cao an xoa Agan Plus",
  },
];

export const news: NewsType[] = [
  {
    id: 1,
    title:
      "Đông Trùng Hạ Thảo KOVI - Doanh nghiệp tiêu biểu năm 2024 - Đài Truyền Hình Hà Nội",
    content: ` <div class="text"><p>
	<span style="font-size: 18px;">Đông Trùng Hạ Thảo KOVI ( Hợp tác xã Nông nghiệp Dược liệu công nghệ cao KOVI ) tự hào tiếp đón Đài truyền hình Hà Nội tới quay phóng sự doanh nghiệp tiêu biểu của năm 2024.</span></p>
<p>
	<span style="font-size: 18px;">Theo Đài truyền hình Hà Nội đánh giá, KOVI sẽ là 1 trong những doanh nghiệp vô cùng tiềm năng phát triển năm 2025 sắp tới.</span></p>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="" src="https://i.postimg.cc/ncrCTbjn/new1.jpg" style="width: 600px; height: 450px;" /></span></p>
<p>
	<span style="font-size: 18px;">Tại đây hôm nay, KOVI lại thêm cơ hội giới thiệu với công chúng về từng khâu nuôi trồng, sản xuất và đóng gói thành phẩm</span></p>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="" src="https://i.postimg.cc/LsK5HJys/new2.jpg" style="width: 600px; height: 450px;" /><img alt="" src="https://i.postimg.cc/XqJ72dvs/new3.jpg" style="width: 600px; height: 450px;" /></span></p>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="" src="https://i.postimg.cc/gJMG6jK8/new4.jpg" style="width: 600px; height: 450px;" /><img alt="" src="https://i.postimg.cc/5y6b5JPG/new5.jpg" style="width: 600px; height: 450px;" /></span></p>
<p>
	<span style="font-size: 18px;">Đặc biệt, Đài truyền hình Hà Nội cũng vô cùng ấn tượng với sản phẩm đặc biệt mỗi dịp Tết của Đông Trùng Hạ Thảo KOVI.</span></p>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="" src="https://i.postimg.cc/tTmH4fXz/new6.jpg" style="width: 600px; height: 800px;" /></span></p>
<p>
	<span style="font-size: 18px;">Với sản phẩm đặc biệt này, đông trùng hạ thảo được cấy và nuôi trồng trực tiếp từ trong lọ thủy tinh, đây là kỹ thuật không phải đơn vị sản xuất nào cũng có thể làm được như KOVI.</span></p>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="" src="https://i.postimg.cc/cCgGgmCG/new7.jpg" style="width: 600px; height: 800px;" /></span></p>
<p>
	<span style="font-size: 18px;">Ngoài ra, các dòng sản phẩm khác vẫn đang được sản xuất &quot;max&quot; công xuất để đáp ứng được nhu cầu tiêu dùng cuối năm 2024 đầu 2025.</span></p>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="" src="https://i.postimg.cc/mZJsx0pL/new8.jpg" style="width: 600px; height: 450px;" /></span></p>
<p style="box-sizing: border-box; margin: 0px 0px 15px; padding: 0px; color: rgb(53, 53, 53); font-family: &quot;Open Sans&quot;, sans-serif;">
	<span style="font-size: 18px;">Đông Trùng Hạ Thảo KOVI rất vui khi được tiếp đón các Đài truyền hình tới quay phóng sự. Đây như minh chứng của sự tin yêu của người tiêu dùng dành cho KOVI.</span></p>
<p style="box-sizing: border-box; margin: 0px 0px 15px; padding: 0px; color: rgb(53, 53, 53); font-family: &quot;Open Sans&quot;, sans-serif;">
	<span style="font-size: 18px;"><span style="box-sizing: border-box;">Đông Trùng Hạ Thảo KOVI sẽ luôn cố gắng để đáp ứng những nhu cầu của người tiêu dùng, đảm bảo mỗi một sản phẩm khi ra thị trường phải là thành phẩm tốt nhất với giá thành hợp lý nhất.</span></span></p>
<p style="box-sizing: border-box; margin: 0px 0px 15px; padding: 0px; color: rgb(53, 53, 53); font-family: &quot;Open Sans&quot;, sans-serif;">
	<span style="font-size: 18px;"><span style="box-sizing: border-box;">KOVI tự hào là đơn vị sản xuất uy tín đưa ra các sản phẩm từ Đông Trùng Hạ Thảo tốt nhất, luôn đạt được những danh hiệu cao và đạt được lòng tin yêu của nhiều người tiêu dùng.</span></span></p>
<p style="box-sizing: border-box; margin: 0px 0px 15px; padding: 0px; color: rgb(53, 53, 53); font-family: &quot;Open Sans&quot;, sans-serif;">
	<span style="font-size: 18px;"><span style="box-sizing: border-box;">Đông Trùng Hạ Thảo KOVI chung tay chăm sóc và bảo vệ sức khỏe cộng đồng, vì sức khỏe người Việt.</span></span></p>
<p style="box-sizing: border-box; margin: 0px 0px 15px; padding: 0px; color: rgb(53, 53, 53); font-family: &quot;Open Sans&quot;, sans-serif;">
	<span style="font-size: 18px;"><span style="box-sizing: border-box;">Mọi người hãy cùng đón xem Đông Trùng Hạ Thảo KOVI trên kênh truyền hình Hà Nội nhé.</span></span></p>
<p style="box-sizing: border-box; margin: 0px 0px 15px; padding: 0px; color: rgb(53, 53, 53); font-family: &quot;Open Sans&quot;, sans-serif;">
	<span style="font-size: 20px;"><span style="box-sizing: border-box;"><span style="box-sizing: border-box; font-weight: 700;">Đông trùng hạ thảo KOVI - Cho cuộc sống khỏe vui.</span></span></span></p>
</div> <hr />`,
    img: newImage,
    description:
      "Đài Truyền hình Hà Nội đưa tin về Đông Trùng Hạ Thảo KOVI nhằm vinh danh doanh nghiệp tiêu biểu năm 2024, tiềm năng phát triển năm 2025",
  },
  {
    id: 2,
    title: "Tạp chí LÀNG NGHỀ VIỆT NAM đưa tin về ĐÔNG TRÙNG HẠ THẢO KOVI",
    content: `<div class="text"><div>
	<p>
		<span style="font-size: 20px;">Theo báo LÀNG NGHỀ VIỆT NAM</span></p>
	<h2>
		<span style="font-size: 20px;">HTX Nông nghiệp dược liệu KOVI thành công với mô hình nuôi cấy đông trùng hạ thảo</span></h2>
	<div>
		<div>
			<span style="font-size: 16px;"><em>LNV - Tiếp nhận chuyển giao và trao đổi công nghệ giữa Việt Nam với các nước như Hàn Quốc, Nhật Bản, Trung Quốc và các viện nghiên cứu hàng đầu Việt Nam, đào tạo được đội ngũ cán bộ kỹ thuật, đã sản xuất, nuôi trồng&nbsp;thành công nấm đông trùng hạ thảo và tập huấn, chuyển giao kỹ thuật cho người dân, hướng tới quảng bá và giới thiệu sản phẩm ra thị trường, nâng cao thu nhập... là những kết quả mà HTX Nông nghiệp dược liệu CNC KOVI đã làm được.</em></span></div>
		<div>
			<div id="__MB_MASTERCMS_EL_3">
				<h2>
					<span style="font-size: 20px;">Quyết tâm chinh phục nuôi cấy đông trùng</span></h2>
				<p>
					<span style="font-size: 18px;">Với tầm nhìn tương lai trở thành đơn vị sản xuất và nghiên cứu, xuất khẩu về đông trùng hạ thảo hàng đầu trong nước và thế giới. Đông trùng hạ thảo Kovi đã thành công nuôi cấy và sản xuất đông trùng hạ thảo trong môi trường nhân tạo, góp phần làm nên thành công trong công cuộc nghiên cứu, sản xuất ra những sản phẩm đông trùng hạ thảo nhân tạo có chất lượng như ngoài tự nhiên với giá cả hợp lý nhất.</span></p>
				<p>
					<span style="font-size: 18px;">Để có được thành công như vậy, là kết quả của quá trình nghiên cứu khoa học, chuyển giao và trao đổi công nghệ giữa Việt Nam với các nước như Hàn Quốc, Nhật Bản, Trung Quốc và các viện nghiên cứu hàng đầu Việt Nam.</span></p>
				<table>
					<tbody>
						<tr>
							<td style="text-align: center;">
								<span style="font-size: 18px;"><img alt="KOVI tự nuôi trồng ra từng cây đông trùng hạ thảo tươi bằng những nguyên liệu hữu cơ. Khi những cây nấm phát triển đạt mức quy định, KOVI tự sấy thăng hoa thành đông trùng hạ thảo khô" data-src="https://langngheviet.com.vn/stores/news_dataimages/2024/122024/06/23/z4470235156374-aa7b0fc6aff4caba1b0440fddfa9234220241206230204.jpg?rt=20241206230209" src="https://langngheviet.com.vn/stores/news_dataimages/2024/122024/06/23/z4470235156374-aa7b0fc6aff4caba1b0440fddfa9234220241206230204.jpg?rt=20241206230209" style="height: 450px; width: 600px;" title="KOVI tự nuôi trồng ra từng cây đông trùng hạ thảo tươi bằng những nguyên liệu hữu cơ. Khi những cây nấm phát triển đạt mức quy định, KOVI tự sấy thăng hoa thành đông trùng hạ thảo khô" /></span></td>
						</tr>
						<tr>
							<td>
								<span style="font-size: 18px;">KOVI tự nuôi trồng ra từng cây đông trùng hạ thảo tươi bằng những nguyên liệu hữu cơ. Khi những cây nấm phát triển đạt mức quy định, KOVI tự sấy thăng hoa thành đông trùng hạ thảo khô.</span></td>
						</tr>
					</tbody>
				</table>
				<p>
					<span style="font-size: 18px;">Anh Ngô Quốc Hưng, Giám đốc HTX Nông nghiệp dược liệu CNC Kovi cho biết, HTX thành lập vào năm 2020, bắt tay vào nghiên cứu nghề trồng nấm từ 4 năm trước, ngay từ thời điểm đó, các thành viên cốt cán HTX đã thấy tiềm năng to lớn từ nghề này. Đối với nghiên cứu tạo thành công các chủng có hiệu lực tốt nhất. Đông trùng hạ thảo Kovi đã trải qua nhiều năm nghiên cứu để chọn ra những cá thể tốt nhất rồi nhân giống cây trồng thử nghiệm trước khi đưa vào sản xuất công nghiệp. Ra thị trường là thành phẩm tốt nhất cho người tiêu dùng, với giá cả hợp lý có thể dễ dàng mua và sử dụng.</span></p>
				<table>
					<tbody>
						<tr>
							<td style="text-align: center;">
								<span style="font-size: 18px;"><img alt="Anh Ngô Quốc Hưng, Giám đốc HTX Nông nghiệp dược liệu CNC Kovi" data-src="https://langngheviet.com.vn/stores/news_dataimages/2024/122024/06/23/1993420241206231149.jpg?rt=20241206231154" src="https://langngheviet.com.vn/stores/news_dataimages/2024/122024/06/23/1993420241206231149.jpg?rt=20241206231154" style="height: 800px; width: 600px;" title="Anh Ngô Quốc Hưng, Giám đốc HTX Nông nghiệp dược liệu CNC Kovi" /></span></td>
						</tr>
						<tr>
							<td>
								<span style="font-size: 18px;">Anh Ngô Quốc Hưng, Giám đốc HTX Nông nghiệp dược liệu CNC Kovi (bìa trái) giới thiệu sản phẩm của mình.</span></td>
						</tr>
					</tbody>
				</table>
				<p>
					<span style="font-size: 18px;">Giám đốc HTX KOVI đau đáu trong lòng rằng: Đông trùng hạ thảo rất tốt, rất hữu ích cho sức khỏe người Việt nhưng giá thành quá đắt mà còn không xác định được rõ nguồn gốc, rất dễ bị lừa bởi hàng kém chất lượng.</span></p>
				<p>
					<span style="font-size: 18px;">HTX đã bắt tay vào cuộc, nghiên cứu từ năm 2019, đông trùng hạ thảo thì có rất nhiều chủng, trong đó có 2 chủng mang lại dược tính cao nhất là Cordyceps sinensis và cordyceps militaris thì KOVI lựa chọn phát triển từ chủng cordyceps militaris vì thời gian sản nuôi trồng ngắn hơn, sớm tới được tay người tiêu dùng mà hàm lượng hoạt chất cũng tương đương với chủng sinensis</span></p>
				<p>
					<span style="font-size: 18px;">Năm 2020, HTX xây dựng nhà máy sản xuất KOVI đạt chứng nhận HACCP với sự kết hợp, chuyển giao công nghệ máy móc, trang thiết bị giữa Việt Nam và các nước Mỹ, Hàn, Nhật, Trung Quốc.</span></p>
				<p>
					<span style="font-size: 18px;">Năm 2022, KOVI chính thức đưa ra thị trường sản phẩm đông trùng hạ thảo nhân tạo có công dụng tương đương với đông trùng hạ thảo tự nhiên của quốc tế do chính người Việt nuôi trồng với sản lượng hơn 800000 hộp tươi, hơn 1000000 con ký chủ, hơn 200 kg khô 1 tháng. Đến năm 2023, tất cả các sản phẩm của KOVI đều đạt chứng nhận 4 sao OCOP.</span></p>
				<table>
					<tbody>
						<tr>
							<td style="text-align: center;">
								<span style="font-size: 18px;"><img alt="Đến năm 2023, tất cả các sản phẩm của KOVI đều đạt chứng nhận 4 sao OCOP. " data-src="https://langngheviet.com.vn/stores/news_dataimages/2024/122024/06/23/z4470234779574-3ec03282d101cbd3b5831f3f84a2d5ae20241206230413.jpg?rt=20241206230417" src="https://langngheviet.com.vn/stores/news_dataimages/2024/122024/06/23/z4470234779574-3ec03282d101cbd3b5831f3f84a2d5ae20241206230413.jpg?rt=20241206230417" style="height: 450px; width: 600px;" title="Đến năm 2023, tất cả các sản phẩm của KOVI đều đạt chứng nhận 4 sao OCOP. " /></span></td>
						</tr>
						<tr>
							<td style="text-align: center;">
								<span style="font-size: 18px;">Năm 2023, tất cả các sản phẩm của KOVI đều đạt chứng nhận OCOP 4 sao.</span></td>
						</tr>
					</tbody>
				</table>
				<p>
					<span style="font-size: 18px;">Đặc biệt tại HTX với những sản phẩm đa dạng như đông trùng hạ thảo tươi, khô (sấy thăng hoa), ký chủ nguyên con và viên nang vô cùng dễ sử dụng, phù hợp cho khách hàng có thể xách đi du lịch, công tác, hoặc di chuyển xa. Trong tương lại, định hướng của HTX sẽ sản xuất thêm những sản phẩm như bột hòa tan, trà thảo mộc.</span></p>
				<p>
					<span style="font-size: 18px;">KOVI tự nuôi trồng ra từng cây đông trùng hạ thảo tươi bằng những nguyên liệu hữu cơ. Khi những cây nấm phát triển đạt mức quy định, KOVI tự sấy thăng hoa thành đông trùng hạ thảo khô (công nghệ này sẽ đảm bảo được hàm lượng dược chất hơn). Sau khi hoàn thành khâu sản xuất, KOVI đóng gói thành phẩm trong những lọ thủy tinh cao cấp, bọc seal kín để bảo quản được lâu dài.</span></p>
				<p>
					<span style="font-size: 18px;">Để nuôi cấy thành công, HTX phải xây dựng một quy trình khép kín vô cùng nghiêm ngặt. Giá thể nuôi trồng Đông trùng hạ thảo là một hỗn hợp gồm các nguyên liệu như là: nước dừa, nhộng tằm, gạo lứt được xay nhuyễn cùng một số vi chất, sau đó giá thể sẽ được đưa vào các lọ cơ chất và hấp tiệt trùng trong vòng khoảng 2 tiếng. Giá thể được chuyển vào phòng lạnh để nguội và bắt đầu nuôi cấy giống.</span></p>
				<h2>
					<span style="font-size: 20px;">Sản phẩm OCOP 4 sao tiêu biểu của huyện Đông Anh</span></h2>
				<p>
					<span style="font-size: 18px;">Tham quan khu nghiên cứu, nuôi trồng và bào chế sâu nấm đông trùng hạ thảo của HTX Nông nghiệp dược liệu CNC KOVI chúng tôi bị cuốn hút vào hàng nghìn hộp nấm hồng đang đâm chồi non trong phòng nuôi cấy.</span></p>
				<p>
					<span style="font-size: 18px;">Nấm được nuôi cấy, trải qua thời gian “ngủ đông” rồi “đâm chồi” trong môi trường, nhiệt độ lý tưởng, tuân thủ theo các quy chuẩn đã được nghiên cứu, đánh giá khắt khe. Mỗi lô nấm là thành quả sau hơn 4 năm nghiên cứu, thực nghiệm của đội ngũ cán bộ kỹ thuật sau khi được chuyển giao công nghệ nuôi trồng.</span></p>
				<p>
					<span style="font-size: 18px;">Hiện nay sản phẩm của HTX đã được đánh giá sản phẩm OCOP 4 sao với đông trùng hạ thảo khô, đông trùng hạ thảo tươi, đông trùng hạ thảo ký chủ nhộng tằm, với hàm lượng dược chất luôn giữ được ở mức Cordycepin &gt;2 và Adenosine &gt;1,5. Toàn bộ sản phẩm đều rõ ràng nguồn gốc do tự nuôi trồng sản xuất. Nhận được nhiều phản hồi tích cực từ người tiêu dùng.</span></p>
				<table>
					<tbody>
						<tr>
							<td>
								<span style="font-size: 18px;"><img alt="Để nuôi cấy thành công, HTX phải xây dựng một quy trình khép kín vô cùng nghiêm ngặt. Giá thể nuôi trồng Đông trùng hạ thảo là một hỗn hợp gồm các " data-src="https://langngheviet.com.vn/stores/news_dataimages/2024/122024/06/23/z4470235347782-26389cc274e517e085fb610740f8aff420241206230544.jpg?rt=20241206230548" src="https://langngheviet.com.vn/stores/news_dataimages/2024/122024/06/23/z4470235347782-26389cc274e517e085fb610740f8aff420241206230544.jpg?rt=20241206230548" style="height: 450px; width: 600px;" title="Để nuôi cấy thành công, HTX phải xây dựng một quy trình khép kín vô cùng nghiêm ngặt. Giá thể nuôi trồng Đông trùng hạ thảo là một hỗn hợp gồm các " /></span></td>
						</tr>
						<tr>
							<td>
								<span style="font-size: 18px;">Để nuôi cấy thành công, HTX phải xây dựng một quy trình khép kín vô cùng nghiêm ngặt.</span></td>
						</tr>
					</tbody>
				</table>
				<p>
					<span style="font-size: 18px;">Thương hiệu KOVI đã có mặt trên thị trường như Hà Nội, Quảng Ninh, Thái Nguyên, TP Hồ Chí Minh. Hỗ trợ tạo công ăn việc làm cho lao động địa phương trong khu vực.</span></p>
				<p>
					<span style="font-size: 18px;">Để tạo được sự liên kết bền vững, HTX đã cung cấp phôi giống cho các bên công ty khác về phôi cấy đông trùng, hỗ trợ hướng dẫn kỹ thuật, quy trình chăm sóc để sản phẩm có chất lượng tốt.</span></p>
				<p>
					<span style="font-size: 18px;">Với tầm nhìn dài hạn, mục tiêu trong năm 2024, HTX sẽ xây dựng và phát triển sàn thương mại điện tử, từng bước đầu tiên của xây dựng hệ thống để 2025 bắt đầu có thêm nhiều đại lý toàn quốc. Không ngừng mở rộng thêm những sản phẩm mới để đa dạng hơn trong việc sử dụng sản phẩm của người tiêu dùng.</span></p>
				<table>
					<tbody>
						<tr>
							<td>
								<span style="font-size: 18px;">Đánh giá về mô hình sản xuất, sơ chế đông trùng hạ thảo, Chi cục Phát triển nông thôn thành phố Hà Nội cho thấy mô hình này rất nhiều tiềm năng. Đồng thời là cơ hội để nhiều người nông dân và những mô hình khác học tập trong cách thay đổi tư duy sản xuất, đổi mới phương thức sản xuất truyền thống sang ứng dụng KH&amp;CN từ việc tiếp cận, tham gia vào chuỗi liên kết sản xuất với HTX thông qua việc thu mua nguyên liệu và hướng dẫn chuyển giao khoa học kỹ thuật, từ đó nâng cao thu nhập, phát triển kinh tế theo hướng bền vững.</span></td>
						</tr>
					</tbody>
				</table>
				<p style="text-align: center;">
					<span style="font-size: 18px;">(Trang thông tin có sự phối hợp với Chi cục Phát triển nông thôn Hà Nội)</span></p>
				<p>
					<span style="font-size: 20px;"><strong>Đông trùng hạ thảo KOVI - Cho cuộc sống khỏe vui.</strong></span></p>
			</div>
		</div>
	</div>`,
    img: newImage1,
    description:
      "Tiếp nhận chuyển giao và trao đổi công nghệ giữa Việt Nam với các nước như Hàn Quốc, Nhật Bản, Trung Quốc và các viện nghiên cứu hàng đầu Việt Nam...",
  },
  {
    id: 3,
    title:
      "Đông Trùng Hạ Thảo KOVI - Hội nghị Biểu dương doanh nghiệp huyện Đông Anh",
    content: `<div class="text"><p>
	<span style="font-size: 18px;">Ngày 05/10/2024, Hợp tác xã Nông nghiệp dược liệu công nghệ cao KOVI tham gia Hội nghị Biểu dương Doanh nghiệp, Doanh nhân tiêu biểu của huyện Đông Anh&nbsp;</span></p>
<p style="text-align: center;">
	<img alt="" src="https://i.postimg.cc/7LS6bShR/new1.jpg" style="width: 600px; height: 450px;" /></p>
<p>
	<span style="font-size: 18px;">Huyện Đông Anh (TP Hà Nội) đã tổ chức Hội nghị gặp mặt biểu dương doanh nghiệp, doanh nhân tiêu biểu huyện Đông Anh nhân kỷ niệm 20&nbsp;năm ngày Doanh nhân Việt Nam.</span></p>
<p>
	<span style="font-size: 18px;">Phát biểu khai mạc hội nghị, Chủ tịch UBND huyện Đông Anh Nguyễn Xuân Linh cho biết, những tháng đầu năm cả nước phải đối mặt với nhiều khó khăn và thách thức, lạm phát toàn cầu mặc dù đã hạ nhiệt nhưng vẫn ở mức cao, Ngân hàng T.Ư của nhiều quốc gia vẫn theo đuổi chính sách tiền tệ thắt chặt đã tác động mạnh tới tăng trưởng kinh tế, đầu tư, tiêu dùng.</span></p>
<p>
	<span style="font-size: 18px;">Trong bối cảnh đó,&nbsp;Huyện uỷ, UBND huyện đã tập trung chỉ đạo, điều hành chủ động, linh hoạt cùng với việc triển khai thực hiện nhiều chính sách, giải pháp để hỗ trợ DN và người dân với quyết tâm tạo động lực đột phá để phát triển kinh tế theo mục tiêu đã đề ra.</span></p>
<p>
	<span style="font-size: 18px;">Cùng với đó, sự đồng thuận, ủng hộ và tham gia tích cực của Nhân dân, cộng đồng DN là yếu tố quan trọng góp phần giữ ổn định tình hình an ninh trật tự, kinh tế - xã hội tiếp tục duy trì được tốc độ tăng trưởng, phát triển ở hầu hết các ngành, lĩnh vực và đạt được những kết quả quan trọng, tạo tiền đề, động lực để huyện hoàn thành nhiệm vụ, kế hoạch của cả năm.</span></p>
<p font-size:="" helvetica="" style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; text-align: center;" text-align:="">
	<span style="display: none;">&nbsp;</span><img alt="" src="https://i.postimg.cc/NfLjm3Lm/new2.jpg" style="width: 600px; height: 450px;" /><span style="display: none;">&nbsp;</span></p>
<p>
	<span style="font-size: 18px;">Đông Trùng Hạ Thảo KOVI vinh dự được vinh danh top đầu doanh nghiệp của huyện, là doanh nghiệp đạt được thành thích cao để các doanh nghiệp khác học hỏi và noi theo.</span></p>
<p font-size:="" helvetica="" style="box-sizing: border-box; margin-top: 0px; margin-bottom: 1rem; text-align: center;" text-align:="">
	<a class="emyt" data-height="337.5" data-id="hShrs77IylU" data-width="600"> <img border="0" height="337.5" src="https://dongtrungkovi.vn/ckeditor/plugins/youtube/images/playyoutube.png" style="background: url(https://i.ytimg.com/vi/hShrs77IylU/hqdefault.jpg) center center black no-repeat;background-size:100%" width="600" /></a></p>
<p>
	<span style="font-size: 18px;">Đông Trùng Hạ Thảo KOVI sẽ luôn sản phẩm ra thị trường những thành phẩm tốt nhất đến tay người tiêu dùng, với giá thành hợp lý nhất ai cũng có thể tiếp cận. </span></p>
<p>
	<span style="font-size: 18px;">KOVI luôn tự hào là đơn vị sản xuất uy tín đưa ra các sản phẩm từ Đông Trùng Hạ Thảo tốt nhất. </span></p>
<p>
	<span style="font-size: 18px;">Đông trùng hạ thảo Kovi chung tay chăm sóc và bảo vệ sức khỏe cộng đồng, vì sức khỏe người Việt.</span></p>
<p>
	<span style="font-size: 18px;">Đông trùng hạ thảo Kovi xin cảm ơn quý khách hàng đã đặt niềm tin vào sản phẩm của chúng tôi.</span></p>
<p>
	<span style="font-size: 18px;">Chúc quý khách lời chúc: Sức Khỏe – An Khang – Thịnh Vượng.&nbsp;</span></p>
<p>
	<span style="font-size: 18px;"><em>Xin chân thành cảm ơn!</em></span></p>
<p>
	<strong>Đông trùng hạ thảo KOVI - Cho cuộc sống khỏe vui.</strong></p>
</div>`,
    img: newImage2,
    description:
      "Ngày 05/10/2024, Hợp tác xã Nông nghiệp dược liệu công nghệ cao KOVI tham gia Hội nghị Biểu dương Doanh nghiệp, Doanh nhân tiêu biểu của huyện Đông Anh",
  },
];

export const sibarItem: NewsType[] = [
  {
    id: 1,
    title: "CHÍNH SÁCH ĐẠI LÝ KOVI",
    content: ` <div class="text"><p style="text-align: justify;">
	<span style="font-size: 18px;">Để lan tỏa sản phẩm <em><strong>Đông trùng hạ thảo KOVI</strong></em> đến với mọi người dùng trong và ngoài nước.&nbsp;</span><span style="font-size: 18px;">Chúng tôi rất mong muốn được hợp tác cùng với các bạn mong muốn tìm hiểu cùng bán sản phẩm </span><strong style="font-size: 18px;">KOVI</strong><span style="font-size: 18px;">. Mỗi một ký kết hợp tác mới với </span><em style="font-size: 18px;"><strong>Đông trùng hạ thảo KOVI</strong></em><span style="font-size: 18px;"> là sự gắn kết tin cậy để chúng ta cùng phát triển đem sản phẩm chất lượng chăm sóc sức khỏe cho người dân. </span></p>
<p style="text-align: justify;">
	<em style="font-size: 18px;"><strong>Đông Trùng Hạ Thảo KOVI</strong></em><span style="font-size: 18px;"> chào đón các bạn tìm hiểu phân phối sản phẩm của chúng tôi trên toàn quốc.</span></p>
<p style="text-align: justify;">
	<span style="font-size: 22px;"><a data-height="315" data-id="sf3Lq_G58M8" data-width="560">Hợp tác với <strong>KOVI </strong>chúng tôi có những lợi thế gì ?</a></span></p>
<ol>
	<li style="text-align: justify;">
		<span style="font-size: 18px;">Nhà máy sản xuất đạt tiêu chuẩn GMP HACCP</span></li>
	<li style="text-align: justify;">
		<span style="font-size: 18px;">Đông Trùng Hạ Thảo KOVI là đơn vị vinh dự đạt chứng nhận Ocop 4 sao với 3 sản phẩm Nấm Đông Trùng Hạ Thảo đầu tiên tại huyện Đông Anh, thành phố Hà Nội</span></li>
	<li style="text-align: justify;">
		<span style="font-size: 18px;">Sản xuất quy mô lớn công nghiệp gía siêu cạnh tranh</span></li>
	<li style="text-align: justify;">
		<span style="font-size: 18px;">Chúng tôi tự hào trung thành với chủng giống nấm dược liệu Nhật với hàm lượng dược chất ổn định đồng đều các lô khác nhau.</span></li>
	<li style="text-align: justify;">
		<span style="font-size: 18px;">Sản phẩm đa dạng từ tươi, khô, thành phẩm viên nang tinh chất, sữa hạt đông trùng, tới các loại dược liệu khác kết hợp cùng Đông Trùng tạo ra các sản phẩm hỗ trợ điều trị bệnh..</span></li>
</ol>
<div style="text-align: center;">
	<span style="font-size: 18px;"><img alt="chính sách kovi" src="https://i.postimg.cc/q7chL4Rm/chinhsach1.png" style="height: 500px; width: 500px;" /></span></div>
<div style="text-align: justify;">
	<span style="font-size: 22px;">Các chính sách hỗ trợ đại lý của <strong>KOVI</strong></span></div>
<ol>
	<li style="text-align: justify;">
		<span style="font-size: 18px;">Được đào tạo miễn phí các kỹ năng mềm về bán hàng, tư vấn thuyết phục, đàm phán khách hàng.</span></li>
	<li style="text-align: justify;">
		<span style="font-size: 18px;">Hỗ trợ hình ảnh truyền thông, content, hướng dẫn cách làm video marketing, bán hàng trên các nền tảng trang thương mại điện tử, Tiktok shop, youtube...</span></li>
	<li style="text-align: justify;">
		<span style="font-size: 18px;">Tham gia các chương trình thi đua tôn vinh đại lý suất sắc nhất.</span></li>
</ol>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="Hợp tác dùng KOVI" src="https://i.postimg.cc/gk60smtv/chinhsach2.png" style="height: 500px; width: 500px;" /></span></p>
<div style="text-align: justify;">
	<span style="font-size: 18px;">Liên hệ ngay với chúng tôi theo thông tin bên dưới để được tư vấn tìm hiểu hợp tác cùng phát triển!&nbsp;</span></div>
<div style="text-align: justify;">
	<span style="font-size: 18px;">Trung tâm nghiên cứu sản xuất</span></div>
<div style="text-align: justify;">
	<span style="font-size: 22px;"><strong>Hợp Tác Xã Nông Nghiệp Và Dược Liệu Công Nghệ Cao KOVI</strong></span></div>
<div style="text-align: justify;">
	<span style="font-size: 18px;">Địa chỉ: thôn Lộc Hà - xã Mai Lâm - huyện Đông Anh - thành phố Hà Nội</span></div>
<div style="text-align: justify;">
	&nbsp;</div>
<div style="text-align: justify;">
	<span style="font-size: 18px;">Văn phòng giao dịch</span></div>
<div style="text-align: justify;">
	<span style="font-size: 18px;">Địa chỉ: Ngõ Thịnh Hào 1, phố Tôn Đức Thắng, quận Đống Đa, Hà Nội.</span></div>
<div style="text-align: justify;">
	<span style="font-size: 18px;">Website: Dongtrungkovi.vn</span></div>
<div style="text-align: justify;">
	<span style="font-size: 18px;">Email: dongtrungkovi@gmail.com</span></div>
<div style="text-align: justify;">
	<strong><span style="font-size: 22px;">Hotline: 09.3449.3449</span></strong></div>
</div>`,
    description: "",
    img: information,
  },
  {
    id: 2,
    title: "QUY TRÌNH SẢN XUẤT ĐÔNG TRÙNG HẠ THẢO KOVI",
    content: `<div class="text"><p style="text-align: justify;">
	<span style="font-size: 18px;">Nguyên liệu sản xuất hữu cơ 100% từ gạo lứt, nhộng tằm, khoai tây, đường gluco.</span></p>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="Dinh dưỡng cho Nấm" src="https://i.postimg.cc/QMP7C0FD/quytrinh1.jpg" style="height: 449px; width: 600px;" /></span></p>
<p style="text-align: justify;">
	<span style="font-size: 18px;">Hỗn hợp nguyên liệu ban đầu đó được hấp tiệt trùng qua nối hấp ở nhiệt độ 121 độ C trong 30 phút để đảm bảo vô khuẩn được gọi là sinh khối để cung cấp dưỡng chất cho nấm <strong>Đông trùng hạ thảo</strong> sinh trưởng và phát triển.</span></p>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="Hấp tiệt trùng" src="https://i.postimg.cc/nVqmVKp8/quytrinh2.jpg" style="height: 800px; width: 600px;" /></span></p>
<p style="text-align: justify;">
	<span style="font-size: 18px;">Các hộp sinh khối đó sau khi được hấp tiệt trùng sẽ được đưa vào phòng cấy giống để nguội và tiến hành cấy giống vào từng hộp sinh khối.</span></p>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="cấy giống Nấm" src="https://i.postimg.cc/ydKXTLTH/quytrinh3.jpg" style="height: 800px; width: 600px;" /></span></p>
<p style="text-align: justify;">
	<span style="font-size: 18px;">Sau khi cấy giống các hộp sinh khối được đừa vào phòng nuôi tối trong 7 ngày tạo thể sợi rồi mới đưa sang phòng nuôi sáng.</span></p>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="Phòng nuôi tối" src="https://i.postimg.cc/nV0YvYjy/quytrinh4.jpg" style="height: 449px; width: 600px;" /></span></p>
<p style="text-align: justify;">
	<span style="font-size: 18px;">&nbsp;Các hộp sinh khối sau khi được chuyển sang phòng nuôi sáng nơi có nhiệt độ, độ ẩm, ánh sáng, không khí mô phòng như môi trường tự nhiên để phần thảo sinh trưởng mọc lên. Phần thảo phát triển tạo quả thể sau 60 ngày sinh trưởng đạt dược chất tốt nhất mới tiền hành thu hoạch sấy khô đưa ra thị trường tiêu thụ tới người dùng.</span></p>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="Phòng nuôi sáng" src="https://i.postimg.cc/CKGbdjp6/quytrinh5.jpg" style="height: 449px; width: 600px;" /></span></p>
<p style="text-align: justify;">
	<span style="font-size: 18px;">Hộp nấm sau khi sinh trưởng phát triển đủ 60 ngày đạt dược chất xuất ra tiêu thụ</span></p>
<p style="text-align: justify;">
	<span style="font-size: 18px;">Thu hoạch đưa vào máy sấy thăng hoa theo công nghệ sấy của Mỹ để giữ được nguyên dược chất tốt nhất có trong nấm <strong>Đông trùng hạ thảo</strong>.</span></p>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="Sấy thăng hoa" src="https://i.postimg.cc/1tM6rstG/quytrinh6.jpg" style="height: 800px; width: 600px;" /></span></p>
<p style="text-align: justify;">
	<span style="font-size: 18px;">Thành phẩm sau khi được đóng gói đưa ra thị trường tiêu thụ</span></p>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="Kovi tại OCOP Việt Nam" src="https://i.postimg.cc/fbf0MDYG/quytrinh7.jpg" style="height: 600px; width: 800px;" /></span></p>
<p style="text-align: justify;">
	<span style="font-size: 18px;"><strong>Đông trùng hạ thảo KOVI</strong> được người dùng lựa chọn tin dùng</span></p>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="Sản phẩm đông trùng hạ thảo KOVI" src="https://i.postimg.cc/HWh8YS7k/quytrinh8.jpg" style="height: 600px; width: 800px;" /></span></p>
<p dir="ltr">
	<em><strong><span style="font-size: 22px;">Đông trùng hạ thảo KOVI - cho cuộc sống khỏe vui.</span></strong></em></p>
<p dir="ltr">
	&nbsp;</p>
<p>
	<span style="font-size: 18px;">Đơn vị sản xuất:</span></p>
<div>
	<p dir="ltr">
		<strong><span style="font-size: 22px;">Hợp Tác Xã Nông Nghiệp Dược Liệu Công Nghệ Cao KOVI</span></strong></p>
	<p dir="ltr">
		<span style="font-size: 18px;">Địa chỉ: Lộc Hà - Mai Lâm - Đông Anh - Hà Nội</span></p>
	<p dir="ltr">
		<span style="font-size: 18px;">Facebook:<a href="https://www.facebook.com/DongtrungnhantaoKoVi">&nbsp;Đông trùng hạ thảo KOVI</a></span></p>
	<p dir="ltr">
		<span style="font-size: 18px;">Youtube:<a href="https://www.youtube.com/@Dongtrunghathaokovi">&nbsp;Đông trùng hạ thảo KOVI</a></span></p>
	<p dir="ltr">
		<span style="font-size: 18px;">Shopee:<a href="https://shopee.vn/dongtrungkovi?uls_trackid=4vqrhock00t8&amp;utm_content=34dYAcuyBv5AkvEWeTB6fY8jVwdZ">&nbsp;Đông trùng hạ thảo KOVI Mall</a></span></p>
	<p dir="ltr">
		<span style="font-size: 18px;">Tiktok:<a href="https://www.tiktok.com/@dongtrunghathao.kovi?_t=8m741r3NLN9&amp;_r=1">&nbsp;Đông trùng hạ thảo KOVI</a></span></p>
	<p dir="ltr">
		<span style="font-size: 22px;"><strong>Hotline: 09.3447.3447 - 09.3449.3449</strong></span></p>
</div>
<div>
	&nbsp;</div>
</div><hr />`,
    description: "",
    img: information1,
  },
  {
    id: 3,
    title:
      "Đông trùng KOVI - Đơn vị cung cấp nguyên liệu chất lượng cao hàng đầu Việt Nam.",
    content: `<div class="text"><p style="text-align: center;">
	<span style="font-size: 18px;">Đông trùng hạ thảo KOVI đơn vị cung cấp nguyên liệu đông trùng hạ thảo chất lượng cao hàng đầu tại Việt Nam.</span></p>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="Đông trùng hạ thảo KOVI đơn vị cung cấp nguyên liệu đông trùng hạ thảo chất lượng cao hàng đầu tại Việt Nam." src="https://i.postimg.cc/VsC0Nq99/donvi1.png"  style={{ width: "100%", height: "auto", objectFit: "contain" }}/></span></p>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="KOVI đơn vị hàng đầu về đông trùng hạ thảo" src="https://i.postimg.cc/PrMxL2CD/donvi2.png" style={{ width: "100%", height: "auto", objectFit: "contain" }}/><img alt="tầm nhìn, sức mệnh, giá trị cốt lõi KOVI" src="https://i.postimg.cc/wjTBDLNG/donvi3.png" style={{ width: "100%", height: "auto", objectFit: "contain" }} /></span></p>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="Nguyên liệu và thành phẩm đông trùng hạ thảo" src="https://i.postimg.cc/rwnwjsCF/donvi4.png" style={{ width: "100%", height: "auto", objectFit: "contain" }}/></span></p>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="Quy trình đông trùng hạ thảo " src="https://i.postimg.cc/RhBShH9G/donvi5.png" style={{ width: "100%", height: "auto", objectFit: "contain" }}/></span></p>
<p style="text-align: justify;">
	<span style="font-size: 18px;">Đông trùng hạ thảo KoVi là đơn vị tiên phong áp dụng sản xuất nông nghiệp công nghệ cao và giá trị trong sản xuất các sản phẩm nông nghiệp tại Việt Nam. Sản phẩm Đông Trùng Hạ Thảo được nuôi trồng thành công là kết quả nghiên cứu, chuyển giao công nghệ giữa Việt Nam và các nước Hàn Quốc, Nhật Bản, Trung Quốc và các đơn vị nghiên cứu hàng đầu trong nước.</span></p>
</div>`,
    description: "",
    img: information2,
  },
];

export const knowledgeItems: NewsType[] = [
  {
    id: 1,
    title: "Polysaccharide là gì ?",
    content: `<div class="text"><p>
	<span style="font-size: 18px;">Polysaccharide là 1 hoạt chất quan trọng có trong đông trùng hạ thảo.<br />
	Vậy Polysaccharide mang lại những công dụng gì mà lại được coi là quan trọng, hãy tìm hiểu qua những phân tích sau</span></p>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="" src="https://i.postimg.cc/P54Dyj2r/new1.jpg" style="width: 600px; height: 338px;" /></span></p>
<h2>
	<span style="font-size: 20px;">Polysaccharide là gì ?</span></h2>
<p>
	<span style="font-size: 18px;">Polysaccharide (hay polisaccarit)&nbsp;là các phân tử lớn bao gồm nhiều monosaccharide nhỏ hơn liên kết với nhau.<br />
	Monosaccharide là những loại đường đơn giản như glucose.&nbsp;<br />
	Một polysaccharide còn được gọi là glycan, thường được tìm thấy trong trái cây, rau, thân cây và các nguồn carbohydrate khác.</span></p>
<p>
	<span style="font-size: 18px;">Polysaccharide được phân thành hai loại chính:&nbsp;polysaccharide&nbsp;tiêu hóa và&nbsp;polysaccharide&nbsp;không tiêu hóa.</span></p>
<h2>
	<span style="font-size: 20px;">Polysaccharide tiêu hóa</span></h2>
<p>
	<span style="font-size: 18px;">Một trong những polysaccharide dễ tiêu hóa được biết đến nhiều nhất là tinh bột.<br />
	Tinh bột được phân hủy qua một loạt các bước trong quá trình tiêu hóa, điển hình là trong miệng và ruột non, cuối cùng tạo ra glucose, sau đó được hấp thụ vào máu.<br />
	Những polysaccharide này là nguồn năng lượng quan trọng, cung cấp khoảng 4 calo mỗi gram. Ngoài ra, chúng còn cung cấp các nguyên tử carbon cần thiết cho quá trình tổng hợp chất béo, protein và các chất khác trong cơ thể.</span></p>
<h2>
	<span style="font-size: 20px;">Polysaccharide không tiêu hóa</span></h2>
<p>
	<span style="font-size: 18px;">Các polysaccharide không tiêu hóa được, thường được gọi là chất xơ, bao gồm&nbsp;cellulose và inulin.<br />
	Những chất xơ này đóng một vai trò quan trọng trong việc thúc đẩy sức khỏe tiêu hóa.<br />
	Ví dụ, cellulose hỗ trợ quá trình vận chuyển thức ăn qua đường ruột, giúp duy trì nhu động ruột đều đặn. Inulin - một loại polysaccharide không tiêu hóa khác, thúc đẩy sự phát triển của vi khuẩn đường ruột có lợi, hỗ trợ hệ vi sinh vật đường ruột khỏe mạnh.</span></p>
<h2>
	<span style="font-size: 20px;">Chức năng của polysaccharide</span></h2>
<p style="text-align: center;">
	<span style="font-size: 20px;"><img alt="" src="https://i.postimg.cc/7YqYVTc0/new2.jpg" style="width: 600px; height: 338px;" /></span></p>
<p>
	<span style="font-size: 18px;">Đến đây bạn đã hiểu polysaccharide là gì rồi.<br />
	Vậy các chức năng chính của polysaccharide ra sao?<br />
	Dưới đây là những thông tin giúp bạn hiểu hơn về chức năng của polysaccharide:</span></p>
<h3>
	<span style="font-size: 18px;">Lưu trữ năng lượng</span></h3>
<p>
	<span style="font-size: 18px;">Polysaccharide đóng một vai trò quan trọng trong việc lưu trữ năng lượng trong sinh vật.<br />
	Chúng được cấu trúc để lưu trữ năng lượng một cách hiệu quả, với tinh bột trong thực vật và glycogen ở động vật là những ví dụ điển hình.<br />
	Những polysacarit này có thể được phân hủy thành glucose khi cần năng lượng, cung cấp nguồn năng lượng sẵn có.</span></p>
<h3>
	<span style="font-size: 18px;">Tính chất kỵ nước</span></h3>
<p>
	<span style="font-size: 18px;">Do có nhiều liên kết hydro trong cấu trúc của chúng nên polysacarit có tính kỵ nước.<br />
	Đặc tính này có nghĩa là chúng không dễ dàng hòa tan trong nước, điều này có lợi cho vai trò của chúng trong việc lưu trữ năng lượng và các chức năng cấu trúc trong tế bào.</span></p>
<h3>
	<span style="font-size: 18px;">Điều hòa nồng độ và hấp thụ chất dinh dưỡng</span></h3>
<p>
	<span style="font-size: 18px;">Polysaccharides giúp duy trì sự cân bằng thẩm thấu bằng cách tác động đến gradient nồng độ trong tế bào.<br />
	Điều này rất quan trọng cho việc hấp thụ chất dinh dưỡng và nước thích hợp, đảm bảo các tế bào hoạt động tối ưu.</span></p>
<h3>
	<span style="font-size: 18px;">Tạo thành glycolipid và glycoprotein</span></h3>
<p>
	<span style="font-size: 18px;">Nhiều polysaccharide được liên kết cộng hóa trị với lipid và protein, tạo thành glycolipid và glycoprotein.<br />
	Các hợp chất này rất cần thiết cho hoạt động giao tiếp của tế bào vì chúng gửi tin nhắn hoặc tín hiệu giữa và trong tế bào.<br />
	Tín hiệu này rất quan trọng đối với nhiều quá trình sinh lý, bao gồm phản ứng miễn dịch và phát triển tế bào.</span></p>
<h3>
	<span style="font-size: 18px;">Hỗ trợ cơ thể</span></h3>
<p>
	<span style="font-size: 18px;">Polysaccharides cung cấp hỗ trợ cơ học cho tế bào.<br />
	Ở thực vật, cellulose - một loại polysaccharide, là thành phần chính của thành tế bào, giúp tế bào có độ cứng và mạnh mẽ.<br />
	Ở động vật, các polysaccharide như chondroitin sulfate góp phần vào sự toàn vẹn cấu trúc của sụn và các mô liên kết khác.</span></p>
<p>
	<span style="font-size: 18px;">Hiểu các chức năng đa dạng của polysaccharide giúp chúng ta đánh giá cao hơn tầm quan trọng của chúng đối với cả quá trình sức khỏe và tế bào.<br />
	Những phân tử này không chỉ quan trọng trong việc lưu trữ năng lượng và hỗ trợ cấu trúc mà còn đóng vai trò quan trọng trong giao tiếp tế bào và hấp thụ chất dinh dưỡng.<br />
	Việc tích hợp thực phẩm giàu polysaccharides vào chế độ ăn uống của bạn có thể hỗ trợ các chức năng sinh học thiết yếu này.</span></p>
<h2>
	<span style="font-size: 20px;">Tác dụng của polysaccharide đối với sức khỏe</span></h2>
<p style="text-align: center;">
	<span style="font-size: 20px;"><img alt="" src="https://i.postimg.cc/J4dVkDW1/new3.jpg" style="width: 600px; height: 338px;" /></span></p>
<p>
	<span style="font-size: 18px;">Polysaccharides không chỉ quan trọng trong việc lưu trữ năng lượng và chức năng tế bào mà còn mang lại những lợi ích đáng kể cho sức khỏe.<br />
	Thông qua nghiên cứu khoa học sâu rộng, polysaccharides đã được chứng minh là có tác động tích cực đến sức khỏe theo nhiều cách khác nhau.<br />
	Dưới đây là những tác dụng chính của polysaccharides đối với sức khỏe:</span></p>
<h3>
	<span style="font-size: 18px;">Hỗ trợ giảm sự phát triển ung thư</span></h3>
<p>
	<span style="font-size: 18px;">Polysaccharides đã cho thấy nhiều hứa hẹn trong việc làm giảm sự phát triển của các khối u mãn tính.<br />
	Mặc dù không gây độc tế bào trực tiếp cho tế bào ung thư&nbsp;nhưng polysaccharides giúp tăng cường phản ứng miễn dịch của cơ thể, mang lại lợi ích chống khối u và chống viêm.<br />
	Chúng cũng thúc đẩy quá trình tự hủy diệt của các tế bào khối u.</span></p>
<p>
	<span style="font-size: 18px;">Ngoài ra, polysaccharides có thể kích thích sản xuất interferon, giúp phục hồi tủy xương bị tổn thương, tăng cường năng lượng và giảm đau khi điều trị ung thư.<br />
	Những đặc tính này làm cho polysaccharides có giá trị khi kết hợp với hóa trị liệu, làm tăng tỷ lệ sống sót và phục hồi của bệnh nhân.</span></p>
<p>
	&nbsp;</p>
<h3>
	<span style="font-size: 18px;">Tăng cường chức năng hệ thống miễn dịch</span></h3>
<p>
	<span style="font-size: 18px;">Môi trường của chúng ta chứa đầy vi khuẩn và virus có hại liên tục đe dọa sức khỏe.<br />
	Hệ thống miễn dịch có thể đặc biệt dễ bị tổn thương khi cơ thể kiệt sức hoặc căng thẳng.<br />
	Polysaccharides có thể tăng cường và điều chỉnh hệ thống miễn dịch bằng cách cải thiện chức năng thực bào của đại thực bào.<br />
	Hoạt động thực bào mạnh hơn có nghĩa là khả năng miễn dịch được tăng cường, mang lại sức đề kháng tốt hơn trước các mầm bệnh nguy hiểm.</span></p>
<h3>
	<span style="font-size: 18px;">Thúc đẩy tái tạo máu</span></h3>
<p>
	<span style="font-size: 18px;">Polysaccharides có hiệu quả trong việc cải thiện chức năng sản xuất máu của tế bào tủy xương.<br />
	Chúng thúc đẩy sự hình thành các tế bào hồng cầu và hạn chế sự tăng sinh của bạch cầu trung tính, có thể giúp chống lại các bệnh về máu&nbsp;như bệnh bạch cầu.<br />
	Do những lợi ích này, polysaccharides thường được khuyên dùng cho bệnh nhân ung thư bạch cầu&nbsp;đang điều trị.</span></p>
<h3>
	<span style="font-size: 18px;">Bảo vệ gan</span></h3>
<p>
	<span style="font-size: 18px;">Polysaccharides hoạt động như một lá chắn bảo vệ gan, có thể bị tổn thương do các tình trạng như viêm gan&nbsp;và xơ gan.</span><br />
	<span style="font-size: 18px;">Những tình trạng này làm tăng men gan trong máu, làm suy giảm chức năng giải độc của gan.<br />
	Bằng chứng khoa học chỉ ra rằng bổ sung polysaccharides có thể làm giảm men gan và tăng cường chức năng thực bào của gan.<br />
	Tác dụng bảo vệ này giúp tối ưu hóa quá trình giải độc của cơ thể và hỗ trợ sức khỏe gan.</span></p>
<h2>
	<span style="font-size: 20px;">Tổng kết</span></h2>
<p style="text-align: center;">
	<span style="font-size: 20px;"><img alt="" src="https://i.postimg.cc/wvzSpDDk/new4.jpg" style="width: 600px; height: 401px;" /></span></p>
<p>
	<span style="font-size: 18px;">Nhìn chung, polysaccgarides mang lại nhiều lợi ích cho sức khỏe, từ tăng cường hệ thống miễn dịch&nbsp;và giảm sự phát triển ung thư đến thúc đẩy tái tạo máu và bảo vệ gan.<br />
	Việc kết hợp polysaccharides vào chế độ ăn uống thông qua đông trùng hạ thảo có thể tăng cường sức khỏe tổng thể và hỗ trợ khả năng phòng vệ tự nhiên của cơ thể.<br />
	Lưu ý là phải lựa chọn sản phẩm chất lượng của cơ sở uy tín để mang lại hiệu quả tốt nhất cũng như sự an toàn cho bản thân.</span></p>
<p>
	<span style="font-size: 20px;"><strong>Đông trùng hạ thảo KOVI - Cho cuộc sống khỏe vui.</strong></span></p>
</div> <hr />`,
    img: knowledge,
    description:
      "Polysaccharide là 1 hoạt chất quan trọng có trong đông trùng hạ thảo. Vậy Polysaccharide mang lại những công dụng gì mà lại được coi là quan trọng, hãy tìm hiểu qua những phân tích sau",
  },
  {
    id: 2,
    title: "OCOP là gì ?",
    content: `<div class="text"><p>
	<span style="font-size: 18px;">Hãy cùng tìm hiểu OCOP là gì và chứng nhận 4 sao OCOP của Đông trùng hạ thảo KOVI “xịn” mức nào nhé.</span></p>
<h2>
	<span style="font-size: 20px;">OCOP là viết tắt của cụm từ gì ?</span></h2>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="" src="https://i.postimg.cc/50PpRzgy/new1.jpg" style="width: 600px; height: 338px;" /></span></p>
<p>
	<span style="font-size: 18px;">OCOP là viết tắt của Mỗi xã một sản phẩm ( One Commune One Product ), là một chương trình quốc gia nhằm phát triển kinh tế nông thôn, nâng cao giá trị sản phẩm địa phương và thu nhập cho người dân. Sản phẩm OCOP được đánh giá và phân hạng theo số sao, từ 1 đến 5 sao, dựa trên các tiêu chí về chất lượng, bao bì, thương hiệu, khả năng cạnh tranh trên thị trường.</span></p>
<h2>
	<span style="font-size: 20px;">Các tiêu chí đánh giá sản phẩm OCOP :</span></h2>
<p style="text-align: center;">
	<img alt="" src="https://i.postimg.cc/PqT4fyFN/new2.jpg" style="width: 600px; height: 338px;" /></p>
<p>
	<span style="font-size: 18px;">Sản phẩm OCOP được đánh giá và phân hạng từ 1 đến 5 sao dựa trên các tiêu chí như:</span></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Chất lượng sản phẩm: Bao gồm các tiêu chí về cảm quan, thành phần dinh dưỡng, an toàn vệ sinh thực phẩm, chất lượng đồng đều.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Quy trình sản xuất: Phải có quy trình sản xuất rõ ràng, được kiểm soát chặt chẽ, đảm bảo chất lượng sản phẩm ổn định.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Bao bì, nhãn mác: Bao bì phải đẹp, thông tin rõ ràng, đầy đủ, nhãn mác phải được cấp phép theo quy định.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Thương hiệu: Sản phẩm phải có thương hiệu riêng, được đăng ký bảo hộ, có chiến lược xây dựng thương hiệu rõ ràng.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Khả năng cạnh tranh: Sản phẩm phải có giá cả hợp lý, chất lượng tốt, đáp ứng nhu cầu của thị trường.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Liên kết sản xuất và tiêu thụ: Sản phẩm phải có hệ thống phân phối ổn định, đảm bảo cung ứng đều đặn cho thị trường.</span></p>
	</li>
</ul>
<h2>
	<span style="font-size: 20px;">Sản phẩm OCOP 4 sao là gì ?</span></h2>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="" src="https://i.postimg.cc/bJFHThY2/new3.jpg" style="width: 600px; height: 327px;" /></span></p>
<p>
	<span style="font-size: 18px;">Sản phẩm OCOP 4 sao là những sản phẩm đã đạt được những tiêu chuẩn khắt khe nhất so với các cấp độ khác. Sản phẩm này thường có những đặc điểm nổi bật sau:</span></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Chất lượng vượt trội: Sản phẩm được sản xuất theo quy trình nghiêm ngặt, đảm bảo chất lượng, an toàn vệ sinh thực phẩm.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Thương hiệu rõ ràng: Sản phẩm có thương hiệu riêng, được đăng ký bảo hộ và nhận biết rộng rãi trên thị trường.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Bao bì đẹp mắt, chuyên nghiệp: Sản phẩm có bao bì thiết kế đẹp, thông tin rõ ràng, thu hút người tiêu dùng.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Khả năng cạnh tranh cao: Sản phẩm có thể cạnh tranh được với các sản phẩm cùng loại trên thị trường, cả trong nước và quốc tế.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Liên kết sản xuất và tiêu thụ: Sản phẩm có hệ thống phân phối ổn định, đảm bảo cung ứng đều đặn cho thị trường.</span></p>
	</li>
</ul>
<h2>
	<span style="font-size: 20px;">Để đạt được 4 sao OCOP, sản phẩm phải đáp ứng các tiêu chí rất cao về :</span></h2>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="" src="https://i.postimg.cc/fTdpJH5v/new4.jpg" style="width: 600px; height: 450px;" /></span></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Chất lượng sản phẩm: Bao gồm các tiêu chí về cảm quan, thành phần dinh dưỡng, an toàn vệ sinh thực phẩm, chất lượng đồng đều.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Quy trình sản xuất: Phải có quy trình sản xuất rõ ràng, được kiểm soát chặt chẽ, đảm bảo chất lượng sản phẩm ổn định.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Bao bì, nhãn mác: Bao bì phải đẹp, thông tin rõ ràng, đầy đủ, nhãn mác phải được cấp phép theo quy định.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Thương hiệu: Sản phẩm phải có thương hiệu riêng, được đăng ký bảo hộ, có chiến lược xây dựng thương hiệu rõ ràng.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Khả năng cạnh tranh: Sản phẩm phải có giá cả hợp lý, chất lượng tốt, đáp ứng nhu cầu của thị trường.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Liên kết sản xuất và tiêu thụ: Sản phẩm phải có hệ thống phân phối ổn định, đảm bảo cung ứng đều đặn cho thị trường.</span></p>
	</li>
</ul>
<h2>
	<span style="font-size: 20px;">Vì sao sản phẩm OCOP 4 sao lại được đánh giá cao ?</span></h2>
<p style="text-align: center;">
	<span style="font-size: 18px;"><img alt="" src="https://i.postimg.cc/dVKXd8xc/new5.jpg" style="width: 600px; height: 450px;" /></span></p>
<ul>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Chất lượng đảm bảo: Người tiêu dùng có thể hoàn toàn yên tâm về chất lượng của sản phẩm OCOP 4 sao.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Độc đáo, mang tính bản địa: Sản phẩm thường mang đậm nét văn hóa, đặc trưng của địa phương, tạo nên sự khác biệt so với các sản phẩm khác trên thị trường.</span></p>
	</li>
	<li aria-level="1" dir="ltr">
		<p>
			<span style="font-size: 18px;">Đóng góp vào phát triển kinh tế địa phương: Việc phát triển các sản phẩm OCOP 4 sao góp phần nâng cao giá trị sản phẩm nông nghiệp, tạo việc làm và tăng thu nhập cho người dân.</span></p>
	</li>
</ul>
<h2>
	<span style="font-size: 20px;">Đông trùng hạ thảo KOVI đạt 4 sao OCOP tất cả các sản phẩm</span></h2>
<p style="text-align: center;">
	<img alt="" src="https://i.postimg.cc/ry0YPn6L/new6.jpg" style="width: 600px; height: 300px;" /></p>
<p>
	<span style="font-size: 18px;">Đây là minh chứng cho chất lượng vượt trội của sản phẩm, được sản xuất theo quy trình nghiêm ngặt và đáp ứng đầy đủ các tiêu chuẩn khắt khe.</span></p>
<p>
	<span style="font-size: 18px;">Đông trùng hạ thảo KOVI rất tự hào rằng sản phẩm đã được vinh danh với danh hiệu 4 sao OCOP. Điều này khẳng định vị thế hàng đầu của sản phẩm trên thị trường, mang đến cho khách hàng những trải nghiệm tuyệt vời về chất lượng và hương vị. Với 4 sao OCOP, khách hàng hoàn toàn yên tâm khi lựa chọn sản phẩm của Đông trùng hạ thảo KOVI.</span></p>
<p dir="ltr">
	<span style="font-size: 18px;">Sản phẩm của Đông trùng hạ thảo KOVI không chỉ là thực phẩm mà còn là tinh hoa của đất trời, được chăm chút tỉ mỉ từng công đoạn nhằm mang đến những sản phẩm độc đáo, mang đậm bản sắc Việt.</span></p>
<p>
	<span style="font-size: 20px;"><strong><em>Đông trùng hạ thảo KOVI - Cho cuộc sống khỏe vui.</em></strong></span></p>
</div> <hr />`,
    img: knowledge1,
    description:
      "Hãy cùng tìm hiểu OCOP là gì và chứng nhận 4 sao OCOP của Đông trùng hạ thảo KOVI “xịn” mức nào nhé.",
  },
  {
    id: 3,
    title: "HACCP là gì ?",
    content: `<div class="text"><p>
	<span style="font-size: 18px;">HACCP hay ISO 22000 đều là những tiêu chuẩn giúp doanh nghiệp kiểm soát được các mối nguy về an toàn vệ sinh thực phẩm trong toàn bộ quy trình sản xuất.</span></p>
<p>
	<span style="font-size: 18px;">ISO 22000 thì nhiều người tiêu dùng cũng đã biết tới rồi, vậy HACCP là gì, hãy cùng tìm hiểu nhé.</span></p>
<div>
	<h2>
		<span style="font-size: 20px;">HACCP là viết tắt của cụm từ gì ?</span></h2>
	<p style="text-align: center;">
		<span style="font-size: 18px;"><img alt="" src="https://i.postimg.cc/bJKLPPXf/new1.jpg" style="width: 600px; height: 338px;" /></span></p>
	<p>
		<span style="font-size: 18px;">HACCP là viết tắt của Hazard Analysis and Critical Control Points, dịch sang tiếng Việt là Phân tích mối nguy và Điểm kiểm soát tới hạn.&nbsp;</span></p>
	<p>
		<span style="font-size: 18px;">Đây là một hệ thống quản lý an toàn thực phẩm được thiết kế để xác định, đánh giá và kiểm soát các mối nguy có thể gây hại cho sức khỏe con người trong quá trình sản xuất, chế biến và phân phối thực phẩm.</span></p>
	<h2>
		<span style="font-size: 20px;">Tại sao HACCP lại quan trọng ?</span></h2>
	<p style="text-align: center;">
		<span style="font-size: 18px;"><img alt="" src="https://i.postimg.cc/wxm0V1Jq/new2.jpg" style="width: 600px; height: 338px;" /></span></p>
	<ul>
		<li aria-level="1" dir="ltr">
			<p>
				<span style="font-size: 18px;">Bảo vệ sức khỏe người tiêu dùng: HACCP giúp ngăn ngừa các vụ ngộ độc thực phẩm, đảm bảo sản phẩm an toàn khi đến tay người tiêu dùng.</span></p>
		</li>
		<li aria-level="1" dir="ltr">
			<p>
				<span style="font-size: 18px;">Nâng cao uy tín doanh nghiệp: Chứng nhận HACCP giúp doanh nghiệp khẳng định chất lượng sản phẩm, đáp ứng các yêu cầu của thị trường và tạo dựng niềm tin với khách hàng.</span></p>
		</li>
		<li aria-level="1" dir="ltr">
			<p>
				<span style="font-size: 18px;">Tuân thủ pháp luật: Nhiều quốc gia đã đưa HACCP vào quy định bắt buộc đối với các cơ sở sản xuất, chế biến thực phẩm.</span></p>
		</li>
	</ul>
	<h2>
		<span style="font-size: 20px;">Nguyên tắc của HACCP</span></h2>
	<p style="text-align: center;">
		<img alt="" src="https://i.postimg.cc/TYj0zBCB/new3.jpg" style="width: 600px; height: 338px;" /></p>
	<p>
		<span style="font-size: 18px;">Hệ thống HACCP dựa trên 7 nguyên tắc cơ bản:</span></p>
	<ol>
		<li aria-level="1" dir="ltr">
			<p>
				<span style="font-size: 18px;">Phân tích mối nguy: Xác định các mối nguy sinh học, hóa học và vật lý có thể xảy ra trong quá trình sản xuất.</span></p>
		</li>
		<li aria-level="1" dir="ltr">
			<p>
				<span style="font-size: 18px;">Xác định các Điểm kiểm soát tới hạn (CCP): Xác định các bước trong quy trình sản xuất mà nếu không được kiểm soát có thể dẫn đến sự phát triển của mối nguy.</span></p>
		</li>
		<li aria-level="1" dir="ltr">
			<p>
				<span style="font-size: 18px;">Thiết lập Giới hạn tới hạn: Đặt ra các giới hạn chấp nhận được cho mỗi CCP.</span></p>
		</li>
		<li aria-level="1" dir="ltr">
			<p>
				<span style="font-size: 18px;">Thiết lập hệ thống giám sát: Thực hiện các hoạt động giám sát để đảm bảo rằng các CCP luôn được kiểm soát trong giới hạn cho phép.</span></p>
		</li>
		<li aria-level="1" dir="ltr">
			<p>
				<span style="font-size: 18px;">Thiết lập các hành động khắc phục: Xác định các hành động cần thực hiện khi các CCP vượt quá giới hạn cho phép.</span></p>
		</li>
		<li aria-level="1" dir="ltr">
			<p>
				<span style="font-size: 18px;">Thiết lập các thủ tục kiểm tra xác nhận: Kiểm tra xem hệ thống HACCP có hoạt động hiệu quả hay không.</span></p>
		</li>
		<li aria-level="1" dir="ltr">
			<p>
				<span style="font-size: 18px;">Thiết lập hệ thống ghi chép: Ghi lại tất cả các thông tin liên quan đến hệ thống HACCP.</span></p>
		</li>
	</ol>
	<h2>
		<span style="font-size: 20px;">Điểm Giống Nhau Giữa HACCP và ISO 22000</span></h2>
	<p style="text-align: center;">
		<span style="font-size: 18px;"><img alt="" src="https://i.postimg.cc/sg6YyzSH/new4.jpg" style="width: 600px; height: 338px;" /></span></p>
	<ul>
		<li aria-level="1" dir="ltr">
			<p>
				<span style="font-size: 18px;">Mục tiêu chung: Cả hai hệ thống đều hướng tới mục tiêu đảm bảo an toàn thực phẩm, giảm thiểu rủi ro về vệ sinh an toàn thực phẩm trong quá trình sản xuất, chế biến và phân phối.</span></p>
		</li>
		<li aria-level="1" dir="ltr">
			<p>
				<span style="font-size: 18px;">Nguyên tắc cơ bản: Cả hai đều dựa trên nguyên tắc phân tích mối nguy và xác định các điểm kiểm soát tới hạn (CCP) để kiểm soát các mối nguy đó.</span></p>
		</li>
		<li aria-level="1" dir="ltr">
			<p>
				<span style="font-size: 18px;">Phạm vi áp dụng: Cả hai hệ thống đều có thể áp dụng cho tất cả các khâu trong chuỗi cung ứng thực phẩm, từ sản xuất đến tiêu dùng.</span></p>
		</li>
		<li aria-level="1" dir="ltr">
			<p>
				<span style="font-size: 18px;">Tập trung vào khách hàng: Cả hai đều nhấn mạnh tầm quan trọng của việc đáp ứng yêu cầu của khách hàng về sản phẩm an toàn, chất lượng.</span></p>
		</li>
		<li aria-level="1" dir="ltr">
			<p>
				<span style="font-size: 18px;">Cải thiện liên tục: Cả hai hệ thống đều khuyến khích các tổ chức thực hiện cải tiến liên tục để nâng cao hiệu quả của hệ thống quản lý an toàn thực phẩm.</span></p>
		</li>
	</ul>
	<h2>
		<span style="font-size: 20px;">Nhà máy của Đông trùng hạ thảo KOVI đạt chuẩn HACCP của Bộ Y tế cấp</span></h2>
	<p style="text-align: center;">
		<img alt="" src="https://i.postimg.cc/MpTB9L47/new5.jpg" style="width: 600px; height: 826px;" /></p>
	<p>
		<span style="font-size: 18px;">Hợp tác xã nông nghiệp dược liệu công nghệ cao KOVI đã vinh dự được Bộ Y tế cấp chứng nhận HACCP. Đây là kết quả của quá trình nỗ lực không ngừng nhằm xây dựng một hệ thống quản lý an toàn thực phẩm hiện đại, đáp ứng tiêu chuẩn quốc tế. Chứng nhận HACCP là minh chứng rõ ràng nhất cho chất lượng sản phẩm của Đông trùng hạ thảo KOVI, đảm bảo rằng mọi sản phẩm đều được sản xuất trong môi trường vệ sinh, an toàn, từ khâu nguyên liệu đầu vào đến thành phẩm cuối cùng.</span></p>
	<p>
		<span style="font-size: 18px;">Với chứng nhận HACCP, Đông trùng hạ thảo KOVI tự tin khẳng định rằng các sản phẩm của chúng tôi không chỉ thơm ngon, bổ dưỡng mà còn hoàn toàn an toàn cho sức khỏe. Chúng tôi cam kết luôn đặt sức khỏe của người tiêu dùng lên hàng đầu và không ngừng cải tiến quy trình sản xuất để mang đến những sản phẩm tốt nhất.</span></p>
	<p>
		<span style="font-size: 20px;"><strong><em>Đông trùng hạ thảo KOVI - Cho cuộc sống khỏe vui.</em></strong></span></p>
</div>
</div> <hr />`,
    img: knowledge2,
    description:
      "HACCP hay ISO 22000 đều là những tiêu chuẩn giúp doanh nghiệp kiểm soát được các mối nguy về an toàn vệ sinh thực phẩm trong toàn bộ quy trình sản xuất. ISO 22000 thì nhiều người tiêu dùng cũng đã biết tới rồi, vậy HACCP là gì, hãy cùng tìm...",
  },
];
