import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

const Navbar: React.FC = () => {
  const navigator = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        {/* Logo */}
        <div className="logo">
            <div className="d-flex align-items-center">
              <i className="bi bi-asterisk text-danger fs-2 me-2"></i>
              <span className="text-danger fw-bold fs-4" onClick={() => navigator('/')}>VanNamkovi</span>
            </div>
          </div>

        {/* Button Toggle Mobile */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* Navbar Content */}
        <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}>
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <NavLink className="nav-link" to="/" onClick={() => setIsOpen(false)}>
                Trang chủ
              </NavLink>
            </li>

            {/* Dropdown Sản phẩm */}
            <li className="nav-item">
              <NavLink className="nav-link" to="/product" onClick={() => setIsOpen(false)}>
                Sản phẩm
              </NavLink>
            </li>

            {/* <li className="nav-item dropdown">
              <button
                className="nav-link dropdown-toggle w-100 text-start border-0 bg-transparent"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                Sản phẩm
              </button>
              <ul className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}>
                <li>
                  <NavLink className="dropdown-item" to="/product/category1" onClick={closeDropdown}>
                    Danh mục 1
                  </NavLink>
                </li>
                <li>
                  <NavLink className="dropdown-item" to="/product/category2" onClick={closeDropdown}>
                    Danh mục 2
                  </NavLink>
                </li>
                <li>
                  <NavLink className="dropdown-item" to="/product/category3" onClick={closeDropdown}>
                    Danh mục 3
                  </NavLink>
                </li>
              </ul>
            </li> */}

            <li className="nav-item">
              <NavLink className="nav-link" to="/about" onClick={() => setIsOpen(false)}>
                Giới thiệu
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/news" onClick={() => setIsOpen(false)}>
                Tin tức
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/contact" onClick={() => setIsOpen(false)}>
                Liên hệ
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
