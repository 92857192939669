import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  Modal,
  Button,
} from "react-bootstrap";
import { products } from "../../component/data";
import bacgroudProduct1 from "../../public/images/product/bacgroudProduct1.png";
import { useNavigate } from "react-router-dom";

interface ProductShow {
  show: boolean;
  product: any;
}

const Product: React.FC = () => {
  const [product, setProduct] = React.useState<ProductShow>({
    show: false,
    product: {},
  });
  const navigate = useNavigate();
  return (
    <div className="site-wrap">
      <div className="site-section">
        <Container>
          <h2 className="text-center mb-4">SẢN PHẨM</h2>
          <Row>
            {products.map((product) => (
              <Col
                key={product.id}
                xs={6}
                md={4}
                lg={4}
                className="mb-4"
                onClick={() => {
                  navigate(`/product/${product.id}`);
                  // setProduct({ show: true, product })
                }}
              >
                <Card className="h-100 text-center border-0">
                  <Card.Img
                    variant="top"
                    src={product.img}
                    alt={product.name}
                    style={{ height: "250px", objectFit: "cover" }}
                  />
                  <Card.Body>
                    <Card.Title className="fw-bold">{product.name}</Card.Title>
                    <Card.Text>
                      <span className="text-danger fw-bold">
                        {product.price.toLocaleString()}đ
                      </span>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>

      <div className="site-section">
        <Container>
          <h3 className="text-center mb-4">Đông trùng hạ thảo KOVI</h3>
          <Row>
            <Col md={12}>
              <p className="mt-3">
                Đông trùng hạ thảo vốn được biết đến là một loại dược liệu quý
                hiếm và đắt tiền trong Đông Y với nhiều lợi ích về sức khỏe
                tuyệt vời cho mọi đối tượng. Hợp tác xã nông nghiệp dược liệu
                công nghệ cao KOVI là đơn vị tiên phong áp dụng sản xuất nông
                nghiệp công nghệ cao và giá trị trong sản xuất các sản phẩm nông
                nghiệp tại Việt Nam. Sản phẩm Đông Trùng Hạ Thảo được nuôi trồng
                thành công là kết quả nghiên cứu, chuyển giao công nghệ giữa
                Việt Nam và các nước Hàn Quốc, Nhật Bản, Trung Quốc và Các đơn
                vị nghiên cứu hàng đầu trong Nước.
              </p>

              <ul className="list-group">
                <li className="list-group-item">
                  1. Đông Trùng Hạ Thảo KOVI là đơn vị tiên phong sản xuất quy
                  mô lớn công nghiệp nên tối ưu được chi phí sản phẩm ra chất
                  lượng giá thành hợp lý
                </li>
                <li className="list-group-item">
                  2. Công nghệ nuôi trồng hàng đầu Việt Nam được đội ngũ nghiên
                  cứu từ Nhật Bản, Hàn Quốc, các viện nghiên cứu trường đại học
                  trong nước.
                </li>
                <li className="list-group-item">
                  3. Giống thuần chủng F1 với hàm lượng hoạt chất Adenosine
                  &gt;=1 Cordycepin &gt;=2
                </li>
                <li className="list-group-item">
                  4. Nhà xưởng tiêu chuẩn phòng sạch HACCP tiêu chuẩn được thế
                  giới công nhận đủ tiêu chuẩn xuất khẩu ra nước ngoài.
                </li>
                <li className="list-group-item">
                  5. Sản phẩm nhận chứng nhận Ocop 4 sao đầu tiên của huyện Đông
                  Anh, thành phố Hà Nội. Đông trùng hạ thảo KOVI là đơn vị đầu
                  tiên được cấp chứng nhận sản phẩm Đông Trùng Hạ Thảo 4 sao
                  OCOP tại huyện Đông Anh, thành phố Hà Nội với 3 nhãn là Đông
                  trùng hạ thảo tươi, đông trùng hạ thảo khô sấy thăng hoa, ký
                  chủ nhộng tằm cao cấp. Nhà máy sản xuất đạt chuẩn GMP HACCP
                  (đạt tiêu chuẩn xuất khẩu). Địa chỉ nhà máy tại Thôn Lộc Hà,
                  Xã Mai Lâm, Huyện Đông Anh, Thành Phố Hà Nội.
                </li>
              </ul>

              <p className="mt-3">
                Đông trùng hạ thảo KOVI là đơn vị đầu tiên được cấp chứng nhận
                sản phẩm Đông Trùng Hạ Thảo 4 sao OCOP tại huyện Đông Anh, thành
                phố Hà Nội với 3 nhãn là Đông trùng hạ thảo tươi, đông trùng hạ
                thảo khô sấy thăng hoa, ký chủ nhộng tằm cao cấp. Nhà máy sản
                xuất đạt chuẩn GMP HACCP (đạt tiêu chuẩn xuất khẩu). Địa chỉ nhà
                máy tại Thôn Lộc Hà, Xã Mai Lâm, Huyện Đông Anh, Thành Phố Hà
                Nội.
              </p>

              <h4 className="mt-3">Hotline: 09.2962.897</h4>

              <Image
                src={bacgroudProduct1}
                alt=""
                fluid
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
              <h3>Đông trùng hạ thảo KOVI - Cho cuộc sống khỏe vui.</h3>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <Modal
        show={product.show}
        onHide={() => setProduct({ show: false, product: {} })}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="content-container"
            style={{ wordWrap: "break-word", overflow: "hidden" }}
            dangerouslySetInnerHTML={{ __html: product?.product?.content }}
          />
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default Product;
